import React from 'react';
import styled from 'styled-components';
import { Fraction } from './comps';
import { FractionBarValue } from '../../types';

const Wrap = styled.div`
  display: flex;
  align-items: center;

  .fcontainer {
    height: 11px;
    flex: 1;
    display: flex;
    grid-gap: 4px;
  }
`;

interface FractionBarProps {
  values: FractionBarValue[];
  labelBottom?: boolean;
  disabled?: boolean;
}

export const FractionBar: React.FC<FractionBarProps> = ({ values, labelBottom, disabled }) => {
  return (
    <Wrap>
      <div className="fcontainer">
        {values.map((el, idx) => {
          if (!el.value) return null;
          return <Fraction key={idx} value={el} labelBottom={labelBottom} disabled={disabled} />;
        })}
      </div>
    </Wrap>
  );
};
