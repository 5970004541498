// @ts-nocheck
import React from 'react';
import { Incident } from 'src/api/types';
import { Image, Link, Text, View } from '@react-pdf/renderer';
import colors from 'src/theme/colors';
import { pdfStyles } from '../pdfStyles';
import { formatDate, getExternalPath } from '../util';
import { extLinkImg } from '../imgData';

interface IncidentsTableProps {
  data: Incident[];
}

export const IncidentsTable: React.FC<IncidentsTableProps> = ({ data }) => {
  return (
    <>
      <View
        wrap={false}
        break={false}
        style={{
          height: 24,
          border: `1px solid ${colors.cflowerBlue}`,
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <View style={{ ...pdfStyles.tableCell, flex: 0.4 }}>
          <Text style={pdfStyles.tableHeader}>Reference</Text>
        </View>
        <View style={{ ...pdfStyles.tableCell, flex: 0.8 }}>
          <Text style={pdfStyles.tableHeader}>Name</Text>
        </View>
        <View style={{ ...pdfStyles.tableCell, flex: 0.4 }}>
          <Text style={pdfStyles.tableHeader}>Type</Text>
        </View>
        <View style={{ ...pdfStyles.tableCell, flex: 0.4 }}>
          <Text style={pdfStyles.tableHeader}>Date</Text>
        </View>
      </View>
      {data.map((el, idx) => (
        <View
          wrap={false}
          break={false}
          style={{
            minHeight: 24,
            border: `1px solid ${colors.cflowerBlue}`,
            display: 'flex',
            flexDirection: 'row',
            marginTop: -1,
          }}
        >
          <View style={{ ...pdfStyles.tableCell, flex: 0.4 }}>
            <Text style={{ ...pdfStyles.tableValue }}>{el.refId || '-'}</Text>
          </View>
          <View style={{ ...pdfStyles.tableCell, flex: 0.8 }}>
            <Link
              src={getExternalPath(`incidents/${el.id}`)}
              style={{
                alignItems: 'center',
                justifyContent: 'flex-start',
                flexDirection: 'row',
                textDecoration: 'none',
              }}
            >
              <Text style={{ ...pdfStyles.tableValue, textDecoration: 'underline', marginRight: 6 }}>
                {el.name || '-'}
              </Text>
              <Image src={extLinkImg} style={{ width: 8, height: 8 }} />
            </Link>
          </View>
          <View style={{ ...pdfStyles.tableCell, flex: 0.4 }}>
            <Text style={pdfStyles.tableValue}>{el.type || '-'}</Text>
          </View>
          <View style={{ ...pdfStyles.tableCell, flex: 0.4 }}>
            <Text style={pdfStyles.tableValue}>{formatDate(el.date, true)}</Text>
          </View>
        </View>
      ))}
    </>
  );
};
