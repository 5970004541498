import React, { memo, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import colors from 'src/theme/colors';
import CheckBox from 'src/components/form/CheckBox';
import { GradientText, Poppins } from 'src/common';
import _ from 'lodash';
import { Error } from '../';
import MaterialInput from '../../MaterialInput';
import { Clear } from '@mui/icons-material';
import useOnClickOutside from 'src/hooks/useOnClickOutside';
import { InputAdornment } from '@mui/material';

const Menux = styled.div`
  position: absolute;
  transform: translate(0px, 4px);
  right: 0;
  z-index: 10000000;

  .ms-options {
    right: 0;
    padding-top: 12px;
    min-width: 270px;
    background-color: white;
    border: 1px solid ${colors.stroke};
    border-radius: 3px;
    cursor: default;
    display: grid;
    grid-gap: 10px;
    user-select: none;
    box-shadow: 0px 0px 37px rgba(7, 46, 91, 0.23);
    z-index: 100;
    max-height: 300px;
    overflow-y: auto;

    &__title {
      font-size: 14px;
      color: ${colors.cflowerBlue};
      margin: 0 20px;
      margin-bottom: 4px;
    }

    &__item {
      padding: 0 20px;
      display: flex;
      grid-gap: 10px;
      cursor: pointer;
      overflow: hidden;
      align-items: center;

      &--creatable {
        cursor: default;
        justify-content: space-between;

        .msc-delete {
          position: relative;
          z-index: 1;
          color: ${colors.cflowerBlue};
          font-size: 14px;
          cursor: pointer;
          &::before {
            content: '';
            position: absolute;
            width: 25px;
            height: 25px;
            z-index: -1;
            left: -10px;
          }
          &:hover {
            color: ${colors.red};
          }
        }
      }

      &__title {
        font-weight: 500;
        font-size: 14px;
      }
    }

    &__clear {
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      user-select: none;
      border-top: 1px solid ${colors.stroke};
      background-color: ${colors.cultured};
      margin-top: 4px;
      span {
        font-size: 14px;
        color: ${colors.cflowerBlue};
      }

      &:hover {
        cursor: pointer;
        background: ${colors.gradient};
        span {
          color: white;
        }
      }
    }
  }

  .divider {
    border-top: 1px solid ${colors.stroke};
    margin: 10px 0;
    margin: 0 20px;
  }

  .cinput {
    display: none;
    input {
      padding: 0 !important;
    }
  }
`;

const StyledInputAdornment = styled(InputAdornment)`
  cursor: pointer;
  margin-right: 22px;
  &:hover {
    svg {
      opacity: 0.6;
    }
  }
  svg {
    fill: #565863;
    transition: opacity 0.3s;
  }
`;

const StyledInput = styled(MaterialInput)`
  .MuiInputBase-root {
    background-color: ${colors.cultured};
  }

  input,
  textarea {
    padding: 16px 20px;
  }
`;

interface MultiSelectProps<T = string> {
  options: { label: string; value: T }[];
  value: T[];
  onChange: (value: T[]) => void;
  onBlur?: () => void;
  placeholder?: string;
  dataCy?: string;
  className?: string;
  error?: string | false;
  createable?: {
    values: string[];
    onAdd: (value: string) => void;
    onClear: () => void;
    onRemove?: (value: string) => void;
  };
  selectedLabel?: string;
}

export const MultiSelect = memo(
  <T,>({
    options,
    onChange,
    onBlur,
    value,
    dataCy,
    placeholder = 'Select',
    className,
    error,
    createable,
    selectedLabel = 'selected',
  }: MultiSelectProps<T>) => {
    const [creatableText, setCreatableText] = useState('');
    const [creatableErr, setCreatableErr] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    const ref = useRef(null);

    useOnClickOutside(ref, () => {
      setIsOpen(false);
    });

    useEffect(() => {
      if (createable) {
        const normalizeText = (text: string) => text.trim().replace(/\s+/g, ' ');
        const normalizedCreatableText = normalizeText(creatableText);
        const normalizedValues = createable.values.map(normalizeText);

        if (normalizedValues.includes(normalizedCreatableText)) {
          setCreatableErr('Value already exists');
        } else if (creatableText) {
          setCreatableErr(!creatableText.trim() ? 'Value cannot be empty' : '');
        } else {
          setCreatableErr('');
        }
      }
    }, [createable, creatableText]);

    const handleSelect = (key: T) => {
      let values: T[] = [];
      if (value.includes(key)) {
        values = value.filter((el) => el !== key);
      } else {
        values = [...value, key];
      }
      onChange(values);
    };

    const isAllSelected = options.every((el) => value.includes(el.value));
    const totalSelected = value.length + (createable?.values.length || 0);

    const renderClearButton = !!options.length || !!createable?.values.length;
    const canRenderDropdown = renderClearButton || creatableText;

    return (
      <>
        <div css="position: relative" ref={ref}>
          <StyledInput
            className={className}
            value={creatableText}
            onChange={(e) => setCreatableText(e.target.value)}
            fullWidth
            error={!!creatableErr}
            helperText={creatableErr}
            onKeyDown={(e) => e.stopPropagation()}
            placeholder={totalSelected ? `${totalSelected} ${selectedLabel}` : placeholder}
            onFocus={() => setIsOpen(true)}
            // disabled={!createable}
            onBlur={() => {
              onBlur && onBlur();
            }}
            data-cy={dataCy}
            InputProps={{
              endAdornment:
                !!options.length || !!createable?.values.length ? (
                  <StyledInputAdornment position="end" onClick={() => setIsOpen(!isOpen)}>
                    <svg
                      height="20"
                      width="20"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                      focusable="false"
                      style={{
                        transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                      }}
                    >
                      <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                    </svg>
                  </StyledInputAdornment>
                ) : undefined,
              autoComplete: 'off',
            }}
          />
          {isOpen && canRenderDropdown && (
            <Menux className="styled-scroll styled-scroll--themed">
              <div className="ms-options" onClick={(e) => e.stopPropagation()}>
                {creatableText && !creatableErr && (
                  <>
                    <div
                      className="ms-options__item"
                      // onClick={() => onChange(isAllSelected ? [] : options.map((el) => el.value))}
                      onClick={() => {
                        createable?.onAdd(creatableText.trim());
                        setCreatableText('');
                      }}
                    >
                      <GradientText style={{ textTransform: 'none', fontWeight: 500, maxWidth: '88%' }} nowrap>
                        Add "{creatableText}"
                      </GradientText>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                        style={{ marginLeft: 'auto', cursor: 'pointer' }}
                        onClick={() => {
                          createable?.onAdd(creatableText.trim());
                          setCreatableText('');
                        }}
                      >
                        <path
                          d="M16.2792 8.93661L16.2628 7.211L9.42151 7.14586L9.35637 0.304613L7.63076 0.288183L7.6959 7.12943L0.854659 7.06429L0.87109 8.78989L7.71233 8.85504L7.77747 15.6963L9.50308 15.7127L9.43794 8.87147L16.2792 8.93661Z"
                          fill="url(#paint0_linear_8384_11057)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_8384_11057"
                            x1="12.8096"
                            y1="3.75781"
                            x2="4.32428"
                            y2="12.2431"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#E62E61" />
                            <stop offset="1" stopColor="#FD5630" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                    {renderClearButton && <div className="divider" />}
                  </>
                )}

                {!!options.length && (
                  <div
                    className="ms-options__item"
                    onClick={() => onChange(isAllSelected ? [] : options.map((el) => el.value))}
                  >
                    <CheckBox isChecked={isAllSelected} small />
                    <Poppins className="ms-options__item__title" nowrap>
                      All
                    </Poppins>
                  </div>
                )}

                {_.orderBy(options, [(el) => el.label.toLowerCase()]).map((el) => (
                  <div
                    className="ms-options__item"
                    data-cy={`gt-csa-${el.value}`}
                    key={el.value as string}
                    onClick={() => handleSelect(el.value)}
                  >
                    <CheckBox isChecked={value.includes(el.value)} small />
                    <Poppins className="ms-options__item__title" nowrap>
                      {el.label}
                    </Poppins>
                  </div>
                ))}

                {!!createable && (
                  <>
                    <div />
                    {createable.values.map((el) => (
                      <div
                        className="ms-options__item ms-options__item--creatable"
                        data-cy={`gt-csa-${el}`}
                        key={`other-${el}`}
                      >
                        <Poppins className="ms-options__item__title" nowrap>
                          {el}
                        </Poppins>
                        {createable.onRemove && (
                          <div
                            className="msc-delete"
                            onClick={() => (createable?.onRemove ? createable?.onRemove(el) : undefined)}
                          >
                            <Clear fontSize="small" />
                          </div>
                        )}
                      </div>
                    ))}
                  </>
                )}

                {renderClearButton && (
                  <div
                    className="ms-options__clear"
                    onClick={() => {
                      onChange([]);
                      createable?.onClear();
                    }}
                  >
                    <Poppins>Clear all</Poppins>
                  </div>
                )}
              </div>
            </Menux>
          )}
        </div>
        {error && <Error>{error}</Error>}
      </>
    );
  },
  (prev, next) => _.isEqual(prev, next),
) as <T>(props: MultiSelectProps<T>) => JSX.Element;
