import React, { useState } from 'react';
import { Poppins, Spacer } from 'src/common';
import Button from 'src/components/form/Button';
import { useFiltersState } from '../../../state';
import { ContentWrap } from './styled';
import { ListSelector } from './ListSelector';
import { FiltersType } from 'src/api/types/dashboard';
import _ from 'lodash';
import { mapToOptionList, modEnabledRecursive } from 'src/pages/DashboardPage/util';
import { LocalFilters } from '../../../types';

type EventType = 'assessments' | 'scenarios' | 'controls' | 'incidents';

const parseToEventsState = (input: LocalFilters) => {
  const transformed = _.mapValues(input, (items) => {
    return _.mapValues(_.keyBy(items, 'label'), (item) => ({
      tag: item.tag,
      enabled: item.enabled,
    }));
  });
  return _.mapKeys(transformed, (value, key) => _.startCase(key)) as FiltersType['events'];
};

interface ContentEventsProps {
  onClose: () => void;
}

export const ContentEvents: React.FC<ContentEventsProps> = ({ onClose }) => {
  const { filters, setFilters } = useFiltersState();

  const [localFilters, setLocalFilters] = useState<LocalFilters>({
    assessments: mapToOptionList(filters.events?.Assessments),
    scenarios: mapToOptionList(filters.events?.Scenarios),
    controls: mapToOptionList(filters.events?.Controls),
    incidents: mapToOptionList(filters.events?.Incidents),
  });

  const onSelectAll = () => {
    setFilters((prev) => ({
      ...prev,
      events: modEnabledRecursive(prev.events, true),
    }));
    onClose();
  };

  const onApply = () => {
    const events = parseToEventsState(localFilters);
    setFilters((prev) => ({ ...prev, events }));
    onClose();
  };

  const handleOnSelect = (tag: string, type: EventType) => {
    setLocalFilters((prev) => ({
      ...prev,
      [type]: prev[type].map((el) => (el.tag === tag ? { ...el, enabled: !el.enabled } : el)),
    }));
  };

  const handleOnSelectAll = (isAdd: boolean, type: EventType) => {
    setLocalFilters((prev) => ({
      ...prev,
      [type]: prev[type].map((el) => ({ ...el, enabled: isAdd })),
    }));
  };

  return (
    <ContentWrap className="styled-scroll styled-scroll--rounded" style={{ width: 420 }}>
      <div className="chead gutter">
        <Poppins px={18} weight={500}>
          Events
        </Poppins>
        <Poppins
          className="chead__select-all"
          px={14}
          weight={500}
          onClick={() => {
            const isAllSelected =
              localFilters.assessments.every((el) => el.enabled) &&
              localFilters.scenarios.every((el) => el.enabled) &&
              localFilters.controls.every((el) => el.enabled) &&
              localFilters.incidents.every((el) => el.enabled);
            handleOnSelectAll(!isAllSelected, 'assessments');
            handleOnSelectAll(!isAllSelected, 'scenarios');
            handleOnSelectAll(!isAllSelected, 'controls');
            handleOnSelectAll(!isAllSelected, 'incidents');
          }}
        >
          Select All
        </Poppins>
      </div>
      <div className="ccontent">
        <div style={{ display: 'grid', gridGap: 20 }}>
          {!!localFilters.assessments.length && (
            <div>
              <Poppins px={14} weight={500} color="cflowerBlue">
                ASSESSMENTS
              </Poppins>
              <Spacer $px={12} />
              <ListSelector
                data={localFilters.assessments}
                selected={localFilters.assessments.filter((el) => el.enabled).map((el) => el.tag)}
                // onSelectAll={(isAdd) => handleOnSelectAll(isAdd, 'assessments')}
                onSelect={(tag) => handleOnSelect(tag, 'assessments')}
              />
            </div>
          )}
          {!!localFilters.scenarios.length && (
            <div>
              <Poppins px={14} weight={500} color="cflowerBlue">
                RISK SCENARIOS
              </Poppins>
              <Spacer $px={12} />
              <ListSelector
                data={localFilters.scenarios}
                selected={localFilters.scenarios.filter((el) => el.enabled).map((el) => el.tag)}
                // onSelectAll={(isAdd) => handleOnSelectAll(isAdd, 'scenarios')}
                onSelect={(tag) => handleOnSelect(tag, 'scenarios')}
              />
            </div>
          )}
          {!!localFilters.controls.length && (
            <div>
              <Poppins px={14} weight={500} color="cflowerBlue">
                CONTROLS
              </Poppins>
              <Spacer $px={12} />
              <ListSelector
                data={localFilters.controls}
                selected={localFilters.controls.filter((el) => el.enabled).map((el) => el.tag)}
                // onSelectAll={(isAdd) => handleOnSelectAll(isAdd, 'controls')}
                onSelect={(tag) => handleOnSelect(tag, 'controls')}
              />
            </div>
          )}
          {!!localFilters.incidents.length && (
            <div>
              <Poppins px={14} weight={500} color="cflowerBlue">
                INCIDENTS
              </Poppins>
              <Spacer $px={12} />
              <ListSelector
                data={localFilters.incidents}
                selected={localFilters.incidents.filter((el) => el.enabled).map((el) => el.tag)}
                // onSelectAll={(isAdd) => handleOnSelectAll(isAdd, 'incidents')}
                onSelect={(tag) => handleOnSelect(tag, 'incidents')}
              />
            </div>
          )}
        </div>
      </div>
      <div className="btns gutter">
        <Button secondary onClick={onClose}>
          CANCEL
        </Button>
        <Button primary onClick={onApply}>
          APPLY
        </Button>
      </div>
    </ContentWrap>
  );
};
