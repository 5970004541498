import React from 'react';
import colors from 'src/theme/colors';
import styled from 'styled-components';

export const WCard = styled.div`
  background-color: #fff;
  border: 1px solid ${colors.stroke};
  border-radius: 6px;
  overflow: hidden;
  display: grid;
  min-height: 88px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    min-height: 74px;
  }
`;
