import React from 'react';
import { Poppins } from 'src/common';
import colors from 'src/theme/colors';
import styled from 'styled-components';
import _ from 'lodash';

const Div = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 200px;

  .vli-column {
    display: flex;
    align-items: center;

    &__title {
      margin-right: 40px;
    }
    &__dash {
      height: 1px;
      background-color: ${colors.aliceBlue};
      flex: 1;
    }
    &__capsule {
      background-color: ${colors.aliceBlue};
      padding: 2px 16px;
      border-radius: 20px;
      border: 1px solid ${colors.stroke};
      margin: 0 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__value {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    .vli-column {
      &__title {
        margin-right: 8px;
      }
      min-height: 26px;
      &__dash {
        margin-right: 8px;
      }

      &__capsule {
        padding: 0px 12px !important;
        height: 20px;
        max-width: 180px;
        margin: 0 8px;
      }
    }
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    .vli-column {
      &__capsule {
        max-width: 100px;
        margin: 0 8px;
      }
    }
  }
`;

interface ValueRowProps {
  title: string;
  variableName?: string | null;
  value: JSX.Element;
}

export const ValueRow: React.FC<ValueRowProps> = ({ title, variableName, value }) => {
  return (
    <Div className="value-row">
      <div className="vli-column">
        <Poppins px={14} color="davysGrey" className="vli-column__title">
          {title}
        </Poppins>
        <div className="vli-column__dash" />
      </div>
      <div className="vli-column">
        {variableName ? (
          <>
            <div className="vli-column__capsule">
              <Poppins px={14} weight={500} nowrap>
                {variableName}
              </Poppins>
            </div>
            <div className="vli-column__dash" />
          </>
        ) : (
          <div className="vli-column__dash" />
        )}
      </div>
      <div className="vli-column__value">{value}</div>
    </Div>
  );
};
