import styled from 'styled-components';

export const ContentWrap = styled.div`
  padding: 24px 0;
  height: 100%;
  display: flex;
  flex-direction: column;

  .gutter {
    padding: 0 24px;
  }

  .chead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    &__select-all {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .ccontent {
    margin: 0 0 16px 24px;
    flex: 1;
    overflow-y: auto;
    padding-right: 24px;
  }

  .btns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
  }
`;
