import React from 'react';
import { Poppins } from 'src/common';
import colors from 'src/theme/colors';
import styled, { css } from 'styled-components';

interface Props {
  $isPast?: boolean;
}

export const Text = styled(Poppins).attrs({ px: 14 })<Props>`
  ${({ $isPast }) =>
    $isPast &&
    css`
      color: ${colors.cflowerBlue};
    `};
  word-break: break-word;
`;

export const TextLink = styled(Poppins).attrs({ px: 14 })<Props>`
  cursor: pointer;
  font-weight: 500;
  text-decoration: underline;

  ${({ $isPast }) =>
    $isPast &&
    css`
      color: ${colors.cflowerBlue};
      /* text-decoration-color: inherit; */
    `}

  &:hover {
    color: ${colors.red};
  }
`;
