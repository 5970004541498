import React, { useState } from 'react';
import { Document, PDFViewer, Font } from '@react-pdf/renderer';
import fontRegular from 'src/assets/fonts/Poppins-Regular.ttf';
import fontMedium from 'src/assets/fonts/Poppins-Medium.ttf';
import fontBold from 'src/assets/fonts/Poppins-Bold.ttf';
import _ from 'lodash';
import { FooterStartComponent, HtmlWrap, IncidentPage, RmpPage, ScenarioPage, SnapChartImageV2 } from '../comps';
import { auditEvent, getPdfScenario } from 'src/api/other';
import { queryCache, useQuery } from 'react-query';
import { IS_DEBUG } from '../conf';
import { plotLineGradient } from 'src/components/charts/util';

Font.registerHyphenationCallback((word) => [word]);

Font.register({
  family: 'Poppins',

  fonts: [
    {
      src: fontRegular,
      fontWeight: 400,
    },
    {
      src: fontMedium,
      fontWeight: 500,
    },
    {
      src: fontBold,
      fontWeight: 600,
    },
  ],
});

interface PdfScenarioProps {
  assessmentId: string;
  scenarioId: string;
  isLogarithmic: boolean;
  onClose: () => void;
  includesRMP: boolean;
  includesIncidents: boolean;
}

const PdfScenario: React.FC<PdfScenarioProps> = ({
  assessmentId,
  scenarioId,
  isLogarithmic,
  onClose,
  includesRMP,
  includesIncidents,
}) => {
  const [chart, setChart] = useState({ chart: '', hasChartsResults: false });

  const { data } = useQuery(
    ['getPdfScenario', { assessmentId, scenarioId }],
    () => getPdfScenario({ assessmentId, scenarioId }),
    {
      onSuccess: (data) => {
        if (!data.scenarios.length) {
          onClose();
        }
      },
      onError: onClose,
    },
  );

  // FILE DOWNLOAD
  const print = (blob: Blob | undefined) => {
    if (blob) {
      let elm = document.createElement('a');
      elm.href = URL.createObjectURL(blob);
      elm.setAttribute('download', `${data?.scenarios[0].name || 'Scenario'}.pdf`);
      elm.click();
      elm.remove();

      auditEvent({
        action: 'ExportPdf',
        resource: 'scenario',
        scenario_id: scenarioId,
      }).then(() => queryCache.invalidateQueries());

      onClose();
    }
  };

  if (!data) return null;

  return (
    <HtmlWrap $isTest={IS_DEBUG}>
      <SnapChartImageV2
        isLogarithmic={isLogarithmic}
        onSnap={(img) => setChart({ chart: img[0], hasChartsResults: true })}
        chartConf={[
          {
            charts: [
              {
                data: data.scenarios[0].chart || [],
                upper: data.scenarios[0].upper,
                lower: data.scenarios[0].lower,
                color: plotLineGradient,
                fillColor: 'rgba(230, 46, 97, 0.07)',
                name: 'chart',
              },
            ],
          },
        ]}
        disabled={false}
      />

      {chart.hasChartsResults && (
        // @ts-ignore
        <PDFViewer>
          {/* @ts-ignore */}
          <Document
            onRender={(el) => {
              if (!IS_DEBUG) {
                print(el.blob);
              }
            }}
          >
            <ScenarioPage
              scenario={{ ...data.scenarios[0], chartImage: chart.chart }}
              assessmentData={{ reviewDate: data.review_date, id: assessmentId }}
              onRender={() => {}}
            />
            {includesRMP &&
              data.scenarios[0].controls.map((cont) => (
                <RmpPage
                  control={cont}
                  onRender={() => {}}
                  titleSmall
                  footerComponent={
                    <FooterStartComponent title={`${data.scenarios[0].name} / Risk Management Plan / ${cont.name}`} />
                  }
                />
              ))}
            {includesIncidents &&
              data.scenarios[0].incidents.map((inc) => (
                <IncidentPage
                  incident={inc}
                  onRender={() => {}}
                  titleSmall
                  footerComponent={
                    <FooterStartComponent title={`${data.scenarios[0].name} / Incidents / ${inc.name}`} />
                  }
                />
              ))}
          </Document>
        </PDFViewer>
      )}
    </HtmlWrap>
  );
};

export default PdfScenario;
