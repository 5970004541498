import React, { useRef, useState } from 'react';
import colors from 'src/theme/colors';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import useOnClickOutside from 'src/hooks/useOnClickOutside';
import { useFiltersState } from './state';
import { ReactComponent as FilterIcon } from 'src/assets/filter.svg';
import { Poppins } from 'src/common';
import { MenuItem } from './comps';
import { modEnabledRecursive } from '../util';

const Div = styled.div`
  .afltr-control {
    height: 50px;
    width: 50px;
    border: 1px solid ${colors.stroke};
    background-color: ${colors.cultured};
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    * {
      user-select: none;
    }
  }

  .afltr-container {
    position: absolute;
    z-index: 100;
    left: 20px;
    right: 20px;
    display: flex;
    justify-content: flex-end;
    pointer-events: none;

    &__contents {
      width: 268px;
      padding: 25px 20px 20px 20px;
      border: 1px solid ${colors.stroke};
      background-color: ${colors.white};
      box-shadow: 0px 0px 37px rgba(7, 46, 91, 0.23);
      display: grid;
      grid-gap: 12px;
      pointer-events: all;

      &__divider {
        border-bottom: 1px solid ${colors.stroke};
        margin: 3px 0;
      }
      &__restore {
        &:hover {
          background: ${colors.gradient};
          -webkit-background-clip: text;
          color: transparent;
          cursor: pointer;
        }
      }
    }
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    .afltr-control {
      height: 35px;
    }
    .afltr-container {
      left: 15px;
      right: 10px;
      &__contents {
        padding: 20px 25px;
      }
    }
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    .afltr-container {
      left: 10px;
      right: 10px;
      &__contents {
        padding: 15px 10px;
      }
    }
  }
`;

interface FiltersProps {
  owners?: boolean;
}

export const Filters: React.FC<FiltersProps> = ({ owners }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { setModalType, setFilters } = useFiltersState();
  const ref = useRef(null);
  useOnClickOutside(ref, () => setIsOpen(false));

  const handleRestore = () => {
    setFilters((prev) => ({
      // events: modEnabledRecursive(prev.events, true),
      // objects: modEnabledRecursive(prev.objects, true),
      // owners: modEnabledRecursive(prev.owners, true),
      ...modEnabledRecursive(prev, true),
    }));
  };

  return (
    <Div ref={ref} onClick={(e) => e.stopPropagation()}>
      <div className="afltr-control" onClick={() => setIsOpen(!isOpen)}>
        <FilterIcon />
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="afltr-container"
            onClick={(e) => e.stopPropagation()}
            initial={{
              opacity: 0,
              scale: 0.99,
              y: -10,
            }}
            animate={{
              opacity: 1,
              scale: 1,
              y: 10,
            }}
            exit={{
              opacity: 0,
              scale: 0.99,
              y: 0,
            }}
            transition={{
              type: 'tween',
              duration: 0.1,
            }}
          >
            <div className="afltr-container__contents">
              <MenuItem
                title={owners ? 'Owners' : 'Users'}
                onClick={() => {
                  setModalType('users');
                  setIsOpen(false);
                }}
                icon={
                  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="10" viewBox="0 0 13 10" fill="none">
                    <path
                      d="M9.425 5.05C10.322 5.05 11.0435 4.322 11.0435 3.425C11.0435 2.528 10.322 1.8 9.425 1.8C8.528 1.8 7.8 2.528 7.8 3.425C7.8 4.322 8.528 5.05 9.425 5.05ZM4.55 4.4C5.62575 4.4 6.4935 3.52575 6.4935 2.45C6.4935 1.37425 5.62575 0.5 4.55 0.5C3.47425 0.5 2.6 1.37425 2.6 2.45C2.6 3.52575 3.47425 4.4 4.55 4.4ZM9.425 6.35C8.23225 6.35 5.85 6.948 5.85 8.1375V9.6H13V8.1375C13 6.948 10.6177 6.35 9.425 6.35ZM4.55 5.7C3.03225 5.7 0 6.4605 0 7.975V9.6H4.55V8.1375C4.55 7.585 4.76775 6.61975 6.0905 5.882C5.525 5.76175 4.97575 5.7 4.55 5.7Z"
                      fill="#6A7590"
                    />
                  </svg>
                }
              />
              <MenuItem
                title="Events"
                onClick={() => {
                  setModalType('events');
                  setIsOpen(false);
                }}
                icon={
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none">
                    <path
                      d="M8.80283 1.44578H7.60876V1.00441C7.60876 0.726986 7.35884 0.5 7.05338 0.5C6.74791 0.5 6.49799 0.726986 6.49799 1.00441V1.44578H3.41561V1.00441C3.41561 0.726986 3.16569 0.5 2.86023 0.5C2.55477 0.5 2.30484 0.726986 2.30484 1.00441V1.44578H1.11077C0.499845 1.44578 0 1.89975 0 2.4546V9.49117C0 10.046 0.499845 10.5 1.11077 10.5H8.78895C9.39987 10.5 9.89972 10.046 9.89972 9.49117V2.4546C9.9136 1.89975 9.41376 1.44578 8.80283 1.44578ZM8.78895 9.47856H1.11077V5.24149H8.78895V9.47856Z"
                      fill="#6A7590"
                    />
                  </svg>
                }
              />
              <MenuItem
                title="Objects"
                onClick={() => {
                  setModalType('objects');
                  setIsOpen(false);
                }}
                icon={
                  <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 3.88672V8.72166L4.71414 11.4981V6.68574L0 3.88672Z" fill="#6A7590" />
                    <path d="M5.5 11.4981L10.2141 8.72166V3.88672L5.5 6.68574V11.4981Z" fill="#6A7590" />
                    <path
                      d="M9.82184 3.24992L5.1077 0.5L0.393555 3.24992L5.1077 5.99983L9.82184 3.24992Z"
                      fill="#6A7590"
                    />
                  </svg>
                }
              />
              <div className="afltr-container__contents__divider" />
              <Poppins
                className="afltr-container__contents__restore"
                px={14}
                color="cflowerBlue"
                weight={500}
                onClick={() => {
                  handleRestore();
                  setIsOpen(false);
                }}
              >
                Restore Default
              </Poppins>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </Div>
  );
};
