import React, { useState } from 'react';
import {
  DataTitle,
  DataValue,
  IncompleteStateMarker,
  NoData,
  Poppins,
  Settings,
  Spacer,
  TitleWithBack,
  Tooltip,
} from 'src/common';
import CommonMenu from 'src/components/CommonMenu';
import styled from 'styled-components';
import { deleteAssessment, getAssessment, getAssessmentVersions } from 'src/api/assessment';
import { queryCache, useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useAssessment } from 'src/state/assessment';
import { useAuth } from 'src/state/auth';
import { gaEventEXPORT } from 'src/utils/gaEvent';
import {
  AssessmentModal,
  ConfirmDeleteModal,
  PdfExportModal,
  RequestAssistanceModal,
  CopyAssessmentModal,
  ScenarioModal,
  VersionsPublishModal,
  ExecSummaryModal,
  RiskToleranceModalV2,
  VariablesWorkspaceModal,
} from 'src/components/modals';
import { getCSVData } from '../util';
import { useScenario } from 'src/state/scenario';
import Versions from './Versions';
import { css } from 'styled-components';
import useVersion from 'src/hooks/useVersion';
import { Assessment, QKeys } from 'src/api/types';
import colors from 'src/theme/colors';
import Papa from 'papaparse';
import ProjectedRisk from 'src/components/ProjectedRisk';
import { mpEvent } from 'src/utils/mixpanel/useMixPanel';
import { MPEvents } from 'src/utils/mixpanel/types';
import { format, isBefore } from 'date-fns';
import { DATE_FORMAT, DATE_FORMAT_SHORT } from 'src/config';
import useActionsApp from 'src/redux/actionHooks/useActionsApp';
import { WizardIntent } from 'src/redux/reducers/app/types';
import { auditEvent } from 'src/api/other';
import { TLLink } from 'src/pages/DashboardPage/comps';

const Div = styled.div`
  position: relative;
  justify-content: space-between;
  display: flex;

  .heder {
    &__end {
      &__controls {
        display: flex;
        grid-gap: 20px;
        gap: 20px;
        align-items: center;
      }
    }
  }
`;

const Meta = styled.div`
  display: flex;
  justify-content: space-between;

  .mgroup {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    &--20 {
      grid-gap: 20px;
    }
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    flex-direction: column;
  }
`;

const VersionArrow = styled.svg<{ $isOpen: boolean }>`
  ${({ $isOpen }) =>
    $isOpen &&
    css`
      transform: rotate(180deg);
    `}
`;

const Theader = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;

  .versions-controll {
    margin-left: 10px;
    height: 30px;
    width: 30px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    .versions-controll {
      margin-left: 0px;
    }
  }
`;

interface HeadProps {
  isLogarithmic: boolean;
  data: Assessment;
  expectedLossComponent: React.ReactNode;
}

export const Head: React.FC<HeadProps> = ({ isLogarithmic, data, expectedLossComponent }) => {
  const [anchorEl, setAnchorEl] = useState<React.MouseEvent['currentTarget'] | null>(null);
  const [isVariablesModalOpen, setIsVariablesModalOpen] = useState(false);
  const [isRiskToleranceModalOpen, setIsRiskToleranceModalOpen] = useState(false);
  const [isRequestAssistanceModalOpen, setIsRequestAssistanceModalOpen] = useState(false);
  const [isCopyAssessmentModalOpen, setIsCopyAssessmentModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
  const [isExecSummaryModalOpen, setIsExecSummaryModalOpen] = useState(false);
  const [isExportPdfOpen, setIsExportPdfOpen] = useState(false);
  const [isVersionsOpen, setIsVersionsOpen] = useState(false);
  const { openModal: openModalAssesment, isOpen: isOpenAssesment } = useAssessment();
  const { isOpen, closeModal } = useScenario();
  const { version, VHeader } = useVersion();
  const { userFeatures } = useAuth();
  const navigate = useNavigate();
  const { setWizardWorkspace } = useActionsApp();
  const assessmentId = data.id;

  const { data: versionsData } = useQuery([QKeys.Versions, assessmentId], () => getAssessmentVersions(assessmentId));

  const [remove] = useMutation(deleteAssessment, {
    onSuccess: () => {
      mpEvent(MPEvents.AssessmentDelete, { id: assessmentId, tags: ['ASSESSMENT'] });
      queryCache.invalidateQueries(QKeys.Assessments).then(() => {
        handleClose();
      });
    },
  });

  const handleOnDeleteClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    remove({ id: assessmentId }).then(() => {
      navigate(`/`);
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onMenuClick = (e: React.MouseEvent<HTMLLIElement>, cb: () => void) => {
    e.stopPropagation();
    handleClose();
    cb();
  };

  const downloadCsv = () => {
    const rows = getCSVData(data.scenarios);
    const csvString = Papa.unparse(rows);
    const anchor = document.createElement('a');
    anchor.href = 'data:attachment/csv;charset=utf-8,' + encodeURIComponent(csvString);
    anchor.target = '_blank';
    anchor.download = `${data?.name}.csv`;
    anchor.innerHTML = 'test';
    gaEventEXPORT('EXPORT_CSV');

    auditEvent({
      action: 'ExportCsv',
      resource: 'assessment',
      assessment_id: data.id,
    }).then(() => queryCache.invalidateQueries());

    anchor.click();
  };

  if (!data) return null;

  const hasIncompleteTech = data.scenarios.some((el) => el.is_incomplete === 1 || el.is_incomplete === 3);
  const hasControls = data?.scenarios?.some((scenario) => scenario.implementationPlans.length > 0) ?? false;

  const projectedRiskTooltipText =
    data.legacy_version || version
      ? 'Sandbox unavailable for this version.'
      : !hasControls
        ? 'This feature is unavailable until there is a risk scenario with linked controls.'
        : '';

  const isReviewDue = isBefore(new Date(data.review_date || ''), new Date());
  return (
    <>
      {isOpenAssesment && <AssessmentModal />}
      {isExecSummaryModalOpen && (
        <ExecSummaryModal assessmentId={assessmentId} onClose={() => setIsExecSummaryModalOpen(false)} />
      )}
      {isOpen && <ScenarioModal onClose={closeModal} />}
      {isVariablesModalOpen && (
        <VariablesWorkspaceModal
          scope={{
            type: 'assessment',
            id: data.id,
          }}
          onClose={() => setIsVariablesModalOpen(false)}
          readOnly={!!version}
        />
      )}
      {isRiskToleranceModalOpen && (
        <RiskToleranceModalV2
          onClose={() => setIsRiskToleranceModalOpen(false)}
          assessment={data}
          refetchAssessment={() => queryCache.invalidateQueries([QKeys.Assessment, { assessmentId, version }])}
          readOnly={!!version}
        />
      )}
      {isRequestAssistanceModalOpen && (
        <RequestAssistanceModal onClose={() => setIsRequestAssistanceModalOpen(false)} assessmentId={assessmentId} />
      )}
      {isCopyAssessmentModalOpen && (
        <CopyAssessmentModal onClose={() => setIsCopyAssessmentModalOpen(false)} assessmentId={assessmentId} />
      )}
      {isDeleteModalOpen && (
        <ConfirmDeleteModal onConfirm={handleOnDeleteClick} onClose={() => setIsDeleteModalOpen(false)} />
      )}
      {isExportPdfOpen && (
        <PdfExportModal
          onClose={() => setIsExportPdfOpen(false)}
          isLogarithmic={isLogarithmic}
          assessmentId={assessmentId}
        />
      )}
      {isPublishModalOpen && (
        <VersionsPublishModal onClose={() => setIsPublishModalOpen(false)} assessmentId={assessmentId} />
      )}
      <Div>
        {isVersionsOpen && (
          <Versions
            assessmentId={assessmentId}
            versions={versionsData?.versions}
            onClose={() => setIsVersionsOpen(false)}
            top={138}
          />
        )}
        <TitleWithBack gradient title={'ASSESSMENT'} />
        <CommonMenu
          configuredList={[
            !version && {
              type: 'PUBLISH',
              exe: (e) => onMenuClick(e, () => setIsPublishModalOpen(true)),
            },
            !version && {
              type: 'EDIT',
              exe: (e) => onMenuClick(e, () => openModalAssesment(assessmentId)),
            },
            !version && {
              type: 'DUPLICATE',
              exe: (e) => onMenuClick(e, () => setIsCopyAssessmentModalOpen(true)),
            },
            !version && {
              type: 'DELETE',
              exe: (e) => onMenuClick(e, () => setIsDeleteModalOpen(true)),
              divider: true,
            },
            !version && {
              type: 'WIZARD',
              exe: (e) =>
                onMenuClick(e, () =>
                  setWizardWorkspace({
                    intent: {
                      type: WizardIntent.scenario,
                      params: {
                        assessmentData: {
                          id: data.id,
                          name: data.name,
                          review_date: data.review_date,
                          description: data.description,
                          started_date: data.started_date || undefined,
                        },
                      },
                    },
                  }),
                ),
              label: 'Scenario Wizard',
            },
            !version && {
              type: 'EXEC_SUMMARY',
              exe: (e) => onMenuClick(e, () => setIsExecSummaryModalOpen(true)),
            },
            !version && {
              type: 'RISK_TOLERANCE',
              exe: (e) => onMenuClick(e, () => setIsRiskToleranceModalOpen(true)),
            },
            !version && {
              type: 'VARIABLES',
              exe: (e) => onMenuClick(e, () => setIsVariablesModalOpen(true)),
              divider: true,
              label: 'Assessment Variables',
            },
            !version && {
              type: 'PDF',
              exe: (e) => onMenuClick(e, () => setIsExportPdfOpen(true)),
            },

            !version && {
              type: 'CSV',
              exe: (e) => onMenuClick(e, () => downloadCsv()),
              divider: true,
            },
            {
              type: 'ASSISTANCE',
              exe: (e) => onMenuClick(e, () => setIsRequestAssistanceModalOpen(true)),
            },
          ]}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        />
        <div className="heder__end__controls">
          {/* <Tooltip dataHtml={projectedRiskTooltipText} dataId="proj-risk" place="top">
            <ProjectedRisk
              disabled={!hasControls || hasIncompleteTech || data.legacy_version || !!version}
              data={{ assessmentId: assessmentId as string }}
            />
          </Tooltip> */}
          <Settings
            active={!!anchorEl}
            onClick={(e) => {
              e.stopPropagation();
              setAnchorEl(e.currentTarget);
            }}
            data-cy="common-menu"
          />
        </div>
      </Div>
      <Spacer $px={30} />
      <Theader>
        <Poppins px={32} weight={600} data-cy="assessment-name">
          {data.name}
        </Poppins>
        {hasIncompleteTech && (
          <div css="margin-left: 8px;">
            <IncompleteStateMarker
              initState="TECH"
              titleTech="One or more scenarios are missing calculation data and have been excluded from the calculations."
              place="right"
            />
          </div>
        )}
        <div
          className="versions-controll"
          onClick={() => {
            setIsVersionsOpen(!isVersionsOpen);
            mpEvent(MPEvents.ButtonClick, {
              button: 'Versions',
              value: isVersionsOpen ? 'Close' : 'Open',
              tags: ['ASSESSMENT'],
            });
          }}
          data-cy="versions-open-switch"
        >
          <VersionArrow
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="6"
            viewBox="0 0 11 6"
            fill="none"
            $isOpen={isVersionsOpen}
          >
            <path
              d="M9.88629 0H0.659141C0.480523 0 0.326108 0.0652146 0.195643 0.195535C0.0651783 0.326 0 0.480415 0 0.658925C0 0.837435 0.0651783 0.991959 0.195643 1.12235L4.80923 5.73587C4.93984 5.86634 5.09426 5.93166 5.27273 5.93166C5.45121 5.93166 5.60577 5.86634 5.73612 5.73587L10.3497 1.12232C10.48 0.991958 10.5455 0.837435 10.5455 0.658889C10.5455 0.480415 10.4801 0.326 10.3497 0.195499C10.2194 0.06507 10.0648 0 9.88629 0Z"
              fill={colors.cflowerBlue}
            />
          </VersionArrow>
        </div>
      </Theader>
      {VHeader && (
        <>
          <Spacer $px={5} />
          {VHeader}
        </>
      )}
      <Spacer $px={30} />
      <Meta>
        <div className="mgroup mgroup--20">
          <div className="mgroup">
            <DataTitle $noMargin>START DATE:</DataTitle>
            {data.started_date ? (
              <DataValue data-cy="assessment-started_date">
                <TLLink date={data.started_date}>{format(new Date(data.started_date), DATE_FORMAT_SHORT)}</TLLink>
              </DataValue>
            ) : (
              <NoData />
            )}
          </div>
          <div className="mgroup">
            <DataTitle $noMargin>REVIEW DUE:</DataTitle>
            {data.review_date ? (
              <DataValue data-cy="assessment-date" data-highlighted={isReviewDue}>
                <span
                  style={{
                    color: isReviewDue ? colors.error : colors.prussianBlue,
                  }}
                >
                  <TLLink date={data.review_date}>{format(new Date(data.review_date), DATE_FORMAT_SHORT)}</TLLink>
                </span>
              </DataValue>
            ) : (
              <NoData />
            )}
          </div>
        </div>

        <div className="mgroup">
          <DataTitle $noMargin>EXPECTED LOSS:</DataTitle>
          {expectedLossComponent}
        </div>
      </Meta>
    </>
  );
};
