import React, { useState } from 'react';
import { Poppins, Spacer } from 'src/common';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const Div = styled.div`
  display: grid;

  .acor-panel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
    cursor: pointer;
  }
  .acor-container {
    overflow: hidden;
  }
`;

interface AccordionProps {
  title: string;
  children: React.ReactNode;
  initialOpen?: boolean;
}

export const Accordion: React.FC<AccordionProps> = ({ initialOpen = false, title, children }) => {
  const [isOpen, setIsOpen] = useState(initialOpen);
  return (
    <Div>
      <div className="acor-panel" onClick={() => setIsOpen(!isOpen)}>
        <Poppins px={14} weight={600}>
          {title}
        </Poppins>
      </div>
      <motion.div
        className="acor-container"
        initial={{
          height: isOpen ? 'auto' : 0,
        }}
        animate={{
          height: isOpen ? 'auto' : 0,
        }}
        transition={{ type: 'tween', duration: 0.2 }}
      >
        <Spacer $px={14} />
        {children}
      </motion.div>
    </Div>
  );
};
