import styled, { css } from 'styled-components';

export const GridContainer = styled.div<{ $large?: boolean; $scenarios?: boolean }>`
  display: grid;
  grid-gap: ${({ $large }) => ($large ? 16 : 6)}px;
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 12px;
    &__title {
      height: 100%;
      padding-top: 8px;
    }
    &__end {
      width: 225px;
      min-width: 225px;

      &--checkbox {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        grid-gap: 6px;
      }

      &__add-controls {
        flex: 1;
        display: flex;
        align-items: center;
      }
    }
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    grid-gap: ${({ $large }) => ($large ? 10 : 6)}px;
    .row {
      &__end {
        width: 150px;
        min-width: 150px;
      }
    }
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    grid-gap: ${({ $large }) => ($large ? 8 : 4)}px;
    .row {
      &__title {
        padding-top: 10px;
      }
      &__end {
        width: 120px;
        min-width: 120px;
      }
    }
  }

  ${({ $scenarios }) =>
    $scenarios &&
    css`
      .row {
        &__end {
          width: 40px;
          min-width: 40px;
        }
      }
    `}
`;
