import React from 'react';
import { Base } from './Base';
import { NoActionIcon } from './icons';
import { Poppins } from 'src/common';

export const NoAction = ({ text = 'No actions' }) => {
  return (
    <Base
      iconComponent={<NoActionIcon />}
      constentComponent={
        <Poppins px={14} color="cflowerBlue">
          {text}
        </Poppins>
      }
    />
  );
};
