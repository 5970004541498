import React, { useState } from 'react';
import { Poppins, Spacer } from 'src/common';
import Button from 'src/components/form/Button';
import { useFiltersState } from '../../../state';
import { ContentWrap } from './styled';
import TabsMenu from 'src/components/TabsMenu';
import { mapToOptionList } from 'src/pages/DashboardPage/util';
import { LocalFilters, SelectEventType } from 'src/pages/DashboardPage/Filters/types';
import { GenericList } from './GenericList';
import _ from 'lodash';
import { FiltersType } from 'src/api/types/dashboard';
import { useQuery } from 'react-query';
import { getAssessments } from 'src/api/assessment';
import styled from 'styled-components';
import { SearchField } from './SearchField';

const parseToEventsState = (input: LocalFilters) => {
  const transformed = _.mapValues(input, (items) => {
    return _.mapValues(_.keyBy(items, 'label'), (item) => ({
      tag: item.tag,
      enabled: item.enabled,
    }));
  });
  return transformed as FiltersType['objects'];
};

const AssessmentLabel = styled(Poppins)`
  font-size: 14px;
  font-weight: 500;
  color: #6a7590;
  margin: 20px 0 10px 0;
  user-select: none;
  pointer-events: none;
  text-transform: uppercase;
`;

interface ContentObjectsProps {
  onClose: () => void;
  hideIncidents?: boolean;
}

export const ContentObjects: React.FC<ContentObjectsProps> = ({ onClose, hideIncidents }) => {
  const { filters, setFilters } = useFiltersState();
  const [activeTab, setActiveTab] = useState<SelectEventType>('assessments');
  const [localFilters, setLocalFilters] = useState<LocalFilters>({
    assessments: mapToOptionList(filters.objects?.assessments),
    scenarios: mapToOptionList(filters.objects?.scenarios),
    controls: mapToOptionList(filters.objects?.controls),
    incidents: mapToOptionList(filters.objects?.incidents),
  });
  const [search, setSearch] = useState({
    assessments: '',
    scenarios: '',
    controls: '',
    incidents: '',
  });

  const { data: assessments = [], isLoading: isAssessmentsLoading } = useQuery('assessments', getAssessments);

  const groupedScenarios = React.useMemo(() => {
    if (!assessments.length) return {};

    const filteredScenarioTags = localFilters.scenarios.map((filter) => filter.tag);

    return assessments.reduce(
      (acc, assessment) => {
        if (assessment.scenarios && assessment.scenarios.length) {
          const filteredScenarios = assessment.scenarios
            .filter((scenario) => filteredScenarioTags.includes(scenario.id))
            .map((scenario) => ({
              ...scenario,
              label: scenario.name,
              tag: scenario.id,
              enabled: localFilters.scenarios.some((f) => f.tag === scenario.id && f.enabled),
            }))
            .filter((scenario) => scenario.label.toLowerCase().includes(search.scenarios.toLowerCase()));

          if (filteredScenarios.length > 0) {
            acc[assessment.name] = filteredScenarios;
          }
        }
        return acc;
      },
      {} as Record<string, LocalFilters['scenarios']>,
    );
  }, [assessments, localFilters.scenarios, search.scenarios]);

  const handleOnSelect = (tag: string, type: SelectEventType) => {
    setLocalFilters((prev) => ({
      ...prev,
      [type]: prev[type].map((el) => (el.tag === tag ? { ...el, enabled: !el.enabled } : el)),
    }));
  };

  const handleOnSelectAll = (isAdd: boolean, type: SelectEventType) => {
    setLocalFilters((prev) => {
      const shouldModify = (el: { label: string; enabled: boolean }) =>
        !search[type] || el.label.toLowerCase().includes(search[type].toLowerCase());

      return {
        ...prev,
        [type]: prev[type].map((el) => (shouldModify(el) ? { ...el, enabled: isAdd } : el)),
      };
    });
  };

  const onApply = () => {
    const objects = parseToEventsState(localFilters);
    setFilters((prev) => ({ ...prev, objects }));
    onClose();
  };

  const tabs = [
    { label: 'Assessments', value: 'assessments', dataCy: 'assessments' },
    { label: 'Scenarios', value: 'scenarios', dataCy: 'scenarios' },
    { label: 'Controls', value: 'controls', dataCy: 'controls' },
  ];

  if (!hideIncidents) {
    tabs.push({ label: 'Incidents', value: 'incidents', dataCy: 'incidents' });
  }

  return (
    <ContentWrap className="styled-scroll styled-scroll--rounded" style={{ width: hideIncidents ? 420 : 500 }}>
      <div className="chead gutter">
        <Poppins px={18} weight={500}>
          Objects
        </Poppins>
        <Poppins
          className="chead__select-all"
          px={14}
          weight={500}
          onClick={() => {
            const isAllSelected = localFilters[activeTab].every((el) => el.enabled);
            handleOnSelectAll(!isAllSelected, activeTab);
          }}
        >
          Select All
        </Poppins>
      </div>
      <div className="gutter">
        <TabsMenu small tabs={tabs} activeTab={activeTab} setActiveTab={(tab) => setActiveTab(tab)} />
        <Spacer $px={25} />
        {activeTab === 'scenarios' && (
          <SearchField
            onChange={(e) => setSearch((prev) => ({ ...prev, scenarios: e.target.value }))}
            value={search.scenarios}
          />
        )}
      </div>
      <div className="ccontent">
        {activeTab === 'assessments' && (
          <GenericList
            filters={localFilters.assessments}
            handleOnSelect={handleOnSelect}
            // handleOnSelectAll={handleOnSelectAll}
            searchString={search.assessments}
            setSearchString={(value) => setSearch((prev) => ({ ...prev, assessments: value }))}
            type="assessments"
            emptyTextWithSearch="It looks like there aren’t any assessments yet."
          />
        )}
        {activeTab === 'scenarios' && (
          <>
            {Object.entries(groupedScenarios).map(([assessmentName, scenarios]) => (
              <React.Fragment key={assessmentName}>
                <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                  <AssessmentLabel>{assessmentName}</AssessmentLabel>
                </div>
                <GenericList
                  filters={scenarios}
                  handleOnSelect={(tag) => handleOnSelect(tag, 'scenarios')}
                  searchString={search.scenarios}
                  setSearchString={(value) => setSearch((prev) => ({ ...prev, scenarios: value }))}
                  type="scenarios"
                  showSearchField={false}
                  emptyTextWithSearch=""
                />
              </React.Fragment>
            ))}
          </>
        )}
        {activeTab === 'controls' && (
          <GenericList
            filters={localFilters.controls}
            handleOnSelect={handleOnSelect}
            // handleOnSelectAll={handleOnSelectAll}
            searchString={search.controls}
            setSearchString={(value) => setSearch((prev) => ({ ...prev, controls: value }))}
            type="controls"
            emptyTextWithSearch="It looks like controls have not been added yet."
          />
        )}
        {activeTab === 'incidents' && (
          <GenericList
            filters={localFilters.incidents}
            handleOnSelect={handleOnSelect}
            // handleOnSelectAll={handleOnSelectAll}
            searchString={search.incidents}
            setSearchString={(value) => setSearch((prev) => ({ ...prev, incidents: value }))}
            type="incidents"
            emptyTextWithSearch="It looks like incidents have not been created yet."
          />
        )}
      </div>
      <div className="btns gutter">
        <Button secondary onClick={onClose}>
          CANCEL
        </Button>
        <Button primary onClick={onApply}>
          APPLY
        </Button>
      </div>
    </ContentWrap>
  );
};
