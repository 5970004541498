import React from 'react';
import { Poppins } from 'src/common';
import colors from 'src/theme/colors';
import styled from 'styled-components';

const Div = styled.div`
  display: grid;
  grid-template-columns: 12px auto;
  align-items: center;
  grid-gap: 10px;

  &:hover {
    cursor: pointer;
    span {
      background: ${colors.gradient};
      -webkit-background-clip: text;
      color: transparent;
    }
    path {
      fill: ${colors.red};
    }
  }
`;

interface MenuItemProps {
  title: string;
  icon: React.ReactNode;
  onClick: () => void;
}

export const MenuItem: React.FC<MenuItemProps> = ({ title, onClick, icon }) => {
  return (
    <Div onClick={onClick}>
      {icon}
      <Poppins weight={500} color="cflowerBlue">
        {title}
      </Poppins>
    </Div>
  );
};
