export enum MPEvents {
  PageView = 'Page View',
  AssessmentCreate = 'Assessment Create',
  AssessmentUpdate = 'Assessment Update',
  AssessmentDelete = 'Assessment Delete',
  ScenarioCreate = 'Scenario Create',
  ScenarioUpdate = 'Scenario Update',
  ScenarioDelete = 'Scenario Delete',
  ScenariosImportedFromLibrary = 'Scenarios Imported From Library',
  NavClick = 'Top Nav Click',
  SideMenuClick = 'Side Menu Click',
  Logout = 'Logout',
  WorkspaceSwitch = 'Workspace Switch',
  ButtonClick = 'Button Click',
  Link = 'Link',
  DownloadCSVTemplate = 'Download CSV Template',
  FileLoaded = 'File Loaded',
  BackArrowClick = 'Back Arrow Click',
  VersionSwitch = 'Version Switch',
  GraphScaleSwitch = 'Graph Scale Switch',
  GraphLegendToggle = 'Graph Legend Toggle',
  AuditView = 'Audit View',
  TableColumnsChange = 'Table Columns Change',
  TableSortChange = 'Table Sort Change',
  TableSearch = 'Table Search',
  TableRowExpand = 'Table Row Expand',
  TableCheckboxClick = 'Table Checkbox Click',
  ModalCanceled = 'Modal Canceled',
  LogoClick = 'Logo Click',
  SideBarToggle = 'Sidebar Toggle',
  SortOrderChange = 'Sort Order Change',
  VariableAddedToFormula = 'Variable Added To Formula',
  FormulaVariablesOpen = 'Formula Variables Open',
  WizardOpened = 'Wizard Opened',
  WizardClosed = 'Wizard Closed',
  WizardSlideChanged = 'Wizard Slide Changed',
  WizardSubmit = 'Wizard Submit',
  DateToTimelineLink = 'Date To Timeline Link',
}

export type Tags =
  | 'FE'
  | 'NAVIGATION'
  | 'ASSESSMENT'
  | 'SCENARIO'
  | 'RMP'
  | 'INCIDENT'
  | 'FRAMEWORK'
  | 'TIMELINE'
  | 'USER'
  | 'BUTTON'
  | 'CHART'
  | 'TABLE'
  | 'WORKSPACE'
  | 'ORGANISATION'
  | 'MODIFIERS'
  | 'UI'
  | 'SCENARIO LIBRARY'
  | 'SCENARIO TEMPLATE'
  | 'VARIABLES'
  | 'CONTROL'
  | 'WIZARD';

type Buttons =
  | '[select assessment]'
  | '[user click]'
  | '[projected status toggle]'
  | 'Login'
  | 'Sign up'
  | 'Modifier:Add'
  | 'Modifier:Remove'
  | 'Modifier:Save'
  | 'Modifier:Cancel'
  | 'Add'
  | 'Upload'
  | 'Create'
  | 'Create Assessment'
  | 'Update Assessment'
  | 'Add Risk Scenario'
  | 'Update Scenario'
  | 'Compare'
  | 'Compare:cancel'
  | 'Compare:done'
  | 'Compare:card-select'
  | 'Compare:card-unselect'
  | 'Versions'
  | 'Publish'
  | 'Cancel'
  | 'Close'
  | 'Proceed'
  | 'Delete'
  | 'Save'
  | 'Add new variable'
  | 'Submit'
  | 'Export'
  | 'Add to assessment'
  | 'Add to RMP'
  | 'Link'
  | 'Link to scenario'
  | 'Add incident'
  | 'Update'
  | 'Link scenarios'
  | 'Confirm'
  | 'Add a user'
  | 'Invite'
  | 'Link scenarios'
  | 'Save updated modifiers'
  | 'Recalculate'
  | 'Select strategy'
  | 'Add controls'
  | 'Add incidents'
  | 'Add control'
  | 'Edit selected controls'
  | 'Delete selected controls'
  | 'Update selected controls'
  | 'Accept invite'
  | 'Cancel request'
  | 'Request access'
  | 'Join'
  | "What's new"
  | 'Defer'
  | 'Accept'
  | 'Delete variable'
  | 'Toggle currency'
  | 'Reset'
  | 'Assessment Wizard'
  | 'here'
  | 'Override'
  | 'Elipsis:View'
  | 'Elipsis:Publish'
  | 'Elipsis:Edit'
  | 'Elipsis:Delete'
  | 'Elipsis:Variables'
  | 'Elipsis:RiskTolerance'
  | 'Elipsis:ExportCSV'
  | 'Elipsis:ExportPDF'
  | 'Elipsis:Duplicate'
  | 'Elipsis:RequestAssistance'
  | 'Elipsis:UnlinkScenario'
  | 'Elipsis:UnlinkIncident'
  | 'Elipsis:UnlinkControl'
  | 'Elipsis:UnlinkPlan'
  | 'Elipsis:MakeAdmin'
  | 'Elipsis:UnmakeAdmin'
  | 'Elipsis:RemoveUser'
  | 'Elipsis:AcceptUser'
  | 'Elipsis:RejectUser'
  | 'Elipsis:CancelInvite'
  | 'Elipsis:AddToRMP'
  | 'Elipsis:ExecutiveSummary'
  | 'Elipsis:OpenModifier'
  | 'Elipsis:AddControl'
  | 'Elipsis:AddAssessment'
  | 'Elipsis:WorkspaceVariables'
  | 'User menu'
  | 'Edit profile'
  | 'Projected Risk'
  | 'Tab:Enter Risk Details'
  | 'Tab:Import From Library'
  | 'Tab:Import From CSV'
  | 'Tab:Workspace Details'
  | 'Tab:Members'
  | 'Tab:Details'
  | 'Tab:Organisation Details'
  | 'Tab:Enter Incident Details'
  | 'Tab:Link Exisiting Incidents'
  | 'Tab:Enter Control Details'
  | 'Tab:Link Exisiting Controls'
  | 'Tab:Enter Control Details'
  | 'Tab:Import From Framework'
  | 'Tab:Assessment 1'
  | 'Tab:Assessment 2'
  | 'Tab:Comparison View';

export type LinkLocations =
  | 'Assessments'
  | 'Assessment'
  | 'Scenario'
  | 'Scenario Template'
  | 'Scenario Template Detail'
  | 'RMP'
  | 'Control'
  | 'Incidents'
  | 'Incident'
  | 'Timeline'
  | 'Framework'
  | 'Frameworks'
  | 'Framework Controls all'
  | 'Framework Control'
  | 'External URL'
  | 'Compare Assessments'
  | 'Compare Scenarios'
  | 'Organisations'
  | 'Organisation Settings'
  | 'Scenario Library'
  | 'Improvement Plans'
  | 'Workspace Settings'
  | 'Workspace Audit'
  | 'Help'
  | 'Auth'
  | 'Modal:LinkControlsToScenario'
  | 'Modal:LinkIncidentsToScenario'
  | 'Modal:LinkScenariosToControl'
  | 'Modal:UpdateControls'
  | 'Modal:Projected risk:Scenario';

type Modals =
  | 'Guard'
  | 'Assessment modal'
  | 'Scenario add modal'
  | 'Versions publish modal'
  | 'Projected risk:Assessment modal'
  | 'Projected risk:Scenario modal'
  | 'Duplicate assessment modal'
  | 'Confirm delete modal'
  | 'Executive summary modal'
  | 'Risk tolerance modal'
  | 'Variables modal'
  | 'Request Assistance modal'
  | 'Pdf export modal'
  | 'Incident modal'
  | 'Link scenarios to incident modal'
  | 'Workspace add user modal'
  | 'Link scenarios to control modal'
  | 'Manage risk modal'
  | 'Link incidents to scenario modal'
  | 'Link controls to scenario modal'
  | 'Risk management modal'
  | 'Add RMP modal'
  | 'Add Incident modal'
  | 'Update controls modal'
  | 'TSCS modal'
  | 'Wizzard modal'
  | 'Wizzard intro modal';

type VariableType = 'Workspace Variable' | 'Assessment Variable';
interface WithTags {
  tags?: Tags[];
  modal?: Modals;
}

interface ParamsMapBase {
  [MPEvents.PageView]: { path: string };
  [MPEvents.AssessmentCreate]: { id: string };
  [MPEvents.AssessmentUpdate]: { id: string; updatedFields: string[] };
  [MPEvents.AssessmentDelete]: { id: string };
  [MPEvents.ScenarioCreate]: { id: string };
  [MPEvents.ScenarioUpdate]: { id: string; updatedFields: string[] };
  [MPEvents.ScenarioDelete]: { id: string };
  [MPEvents.ScenariosImportedFromLibrary]: { templates: string[] };
  [MPEvents.NavClick]: { value: string };
  [MPEvents.SideMenuClick]: { value: string };
  [MPEvents.Logout]: {};
  [MPEvents.WorkspaceSwitch]: { workspaceID: string };
  [MPEvents.ButtonClick]: {
    button: Buttons;
    value?: any;
    modal?: Modals;
  };
  [MPEvents.Link]: {
    source: { value: LinkLocations[]; params?: any };
    destination: { value: LinkLocations[]; params?: any };
    tags?: Tags[];
  };
  [MPEvents.DownloadCSVTemplate]: {};
  [MPEvents.FileLoaded]: {};
  [MPEvents.BackArrowClick]: {};
  [MPEvents.VersionSwitch]: {};
  [MPEvents.GraphScaleSwitch]: { isLogScale: boolean };
  [MPEvents.GraphLegendToggle]: { value: string };
  [MPEvents.AuditView]: { value: 'open' | 'close' };
  [MPEvents.TableColumnsChange]: { columns?: string[] };
  [MPEvents.TableSortChange]: { id: string; desc: boolean };
  [MPEvents.TableSearch]: {};
  [MPEvents.TableRowExpand]: { value: 'open' | 'close' };
  [MPEvents.TableCheckboxClick]: { value: 'checked' | 'unchecked' };
  [MPEvents.ModalCanceled]: { reason: 'cross' | 'background' };
  [MPEvents.LogoClick]: {};
  [MPEvents.SideBarToggle]: { value: 'open' | 'close' };
  [MPEvents.SortOrderChange]: { value: string };
  [MPEvents.VariableAddedToFormula]: { location: LinkLocations; variableType: VariableType };
  [MPEvents.FormulaVariablesOpen]: { location: LinkLocations };
  [MPEvents.WizardSlideChanged]: { slideId: string };
  [MPEvents.WizardOpened]: {};
  [MPEvents.WizardClosed]: {};
  [MPEvents.WizardSubmit]: { wizardType: 'workspace' | 'assessment' | 'scenario' };
  [MPEvents.DateToTimelineLink]: { date: string };
}

type ParamsMap = {
  [K in MPEvents]: WithTags & ParamsMapBase[K];
};

type Params = {
  [K in keyof ParamsMap]: ParamsMap[K];
};

export type MpEvent = <T extends keyof Params>(event: T, params: Params[T]) => void;
