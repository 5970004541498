import React from 'react';
import { Poppins } from 'src/common';
import CheckBox from 'src/components/form/CheckBox';
import styled from 'styled-components';

const Div = styled.div`
  user-select: none;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  cursor: pointer;
  max-width: 100%;
  overflow: hidden;
  position: relative;
`;

interface CheckItemProps {
  title: string;
  isChecked: boolean;
  onClick: () => void;
  isYou?: boolean;
}

export const CheckItem: React.FC<CheckItemProps> = ({ title, isChecked, onClick, isYou }) => {
  return (
    <Div onClick={onClick}>
      <CheckBox small isChecked={isChecked} />
      <Poppins
        color="davysGrey"
        px={14}
        weight={500}
        nowrap
        style={{ fontStyle: title === '[Blank]' ? 'italic' : undefined, paddingRight: title === '[Blank]' ? 4 : 0 }}
      >
        {title}
        {isYou && (
          <Poppins px={14} weight={500} color="cflowerBlue" nowrap>
            {' '}
            (You)
          </Poppins>
        )}
      </Poppins>
    </Div>
  );
};
