import { ColumnDef, sortingFns } from '@tanstack/react-table';
import React, { useMemo, useState } from 'react';
import { Scenario, riskConsequencesNames, riskEventsNames, riskSourcesNames } from 'src/api/types';
import { GTCheckBox, GTColumnSelectAnchor, GTTdm } from 'src/components/GenericTable';
import { formatDate, numeralFormat } from 'src/utils/misc';
import { filter } from 'lodash';
import { GTInternalIds } from 'src/components/GenericTable/types';
import { gTCheckHandler } from 'src/components/GenericTable/util';
import { IncompleteStateMarker } from 'src/common';
import { Link } from 'react-router-dom';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { TableIds } from 'src/api/types/misc';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { TLLink } from 'src/pages/DashboardPage/comps';

type UseColumns = (a: { data?: Scenario[]; linkedScenarios: string[]; controlId: string }) => {
  columns: ColumnDef<Scenario>[];
  GTColumnSelectAnchorExported: JSX.Element;
  checked: string[];
  resetChecked: () => void;
};

const defaultColumns = ['name', 'expected_loss', 'updated_at'];

export const useScenarioColumns: UseColumns = ({ data = [], linkedScenarios, controlId }) => {
  const [selectedColumns, setSelectedColumns] = useLocalStorage(
    `${TableIds.controlLinkScenarioScenario}-columns`,
    defaultColumns,
  );
  const [checked, setChecked] = useState<string[]>([]);

  const columns = useMemo(() => {
    const internalIds = [GTInternalIds.tdm, GTInternalIds.checker];

    const list: ColumnDef<Scenario>[] = [
      {
        id: GTInternalIds.checker,
        header: ({ table }) => {
          const { isAllChecked, checkAllHandler, visibleRows } = gTCheckHandler(
            {
              checked,
              selector: 'id',
              table,
            },
            setChecked,
          );
          const isAllLinked = visibleRows.every((el) => linkedScenarios.some((linked) => linked === el));

          return (
            <GTCheckBox isChecked={isAllChecked || isAllLinked} onClick={checkAllHandler} disabled={isAllLinked} />
          );
        },
        cell: ({ row }) => {
          const id = row.original.id;
          const isLinked = linkedScenarios.some((el) => el === id);
          return (
            <GTCheckBox
              isChecked={checked.includes(id) || isLinked}
              onClick={() => {
                if (checked.includes(id)) {
                  setChecked(checked.filter((el) => el !== id));
                } else {
                  setChecked([...checked, id]);
                }
              }}
              disabled={isLinked}
            />
          );
        },
        size: 1,
        enableSorting: false,
      },
      {
        id: 'name',
        header: 'Name',
        accessorFn: (row) => row.name,
        cell: (info) => (
          <Link
            className={'table-link'}
            to={`/risk-assessments/${info.row.original.assessment_id}/scenario/${info.row.original.id}`}
            target="_blank"
            onClick={(e) => {
              e.stopPropagation();
              mpEvent(MPEvents.Link, {
                source: { value: ['Control', 'Modal:LinkScenariosToControl'], params: { id: controlId } },
                destination: { value: ['Scenario'], params: { id: info.row.original.id } },
                tags: ['SCENARIO', 'RMP'],
              });
            }}
          >
            {info.getValue()}
          </Link>
        ),
        sortUndefined: 'last',
        sortingFn: sortingFns.text,
        maxSize: 300,
      },
      {
        id: 'source',
        header: 'Source',
        accessorFn: (row) => riskSourcesNames[row.source] || null,
        cell: (info) => info.getValue(),
      },
      {
        id: 'event',
        header: 'Event',
        accessorFn: (row) => riskEventsNames[row.event] || null,
        cell: (info) => info.getValue(),
      },
      {
        id: 'consequence',
        header: 'Consequence',
        accessorFn: (row) => riskConsequencesNames[row.consequence] || null,
        cell: (info) => info.getValue(),
      },
      {
        id: 'risk_owner',
        header: 'Risk Owner',
        accessorFn: (row) => row.risk_owner?.text || null,
        cell: (info) => info.getValue(),
        sortingFn: sortingFns.text,
      },
      {
        id: 'expected_loss',
        header: 'Expected Loss',
        accessorFn: (row) => row.expected_loss || null,
        cell: (info) => numeralFormat(info.getValue() as number),
      },
      {
        id: '_control_count',
        header: 'Linked Controls',
        accessorFn: (row) => row.implementationPlans?.length || 0,
        cell: (info) => info.getValue(),
      },
      {
        id: 'updated_at',
        header: 'Last Updated',
        accessorFn: (row) => row.updated_at || null,
        cell: ({ row }) => {
          const date = row.original.updated_at;
          if (!date) return null;
          return (
            <TLLink date={date} newPage>
              {formatDate(date)}
            </TLLink>
          );
        },
      },
      {
        id: 'created_at',
        header: 'Created Date',
        accessorFn: (row) => row.created_at || null,
        cell: ({ row }) => {
          const date = row.original.created_at;
          if (!date) return null;
          return (
            <TLLink date={date} newPage>
              {formatDate(date)}
            </TLLink>
          );
        },
      },
      {
        id: GTInternalIds.tdm,
        header: () => null,
        cell: ({ row }) => {
          return (
            <div css="display: flex;grid-gap: 8px">
              <GTTdm extraComponentLeft={<IncompleteStateMarker scenario={row.original} />} />
            </div>
          );
        },
        size: 1,
      },
    ];

    return filter(list, (el) => [...internalIds, ...selectedColumns].includes(el.id as string));
  }, [data, selectedColumns, checked]);

  const GTColumnSelectAnchorExported = (
    <GTColumnSelectAnchor
      options={[
        { key: 'name', title: 'Name' },
        { key: 'source', title: 'Source' },
        { key: 'event', title: 'Event' },
        { key: 'consequence', title: 'Consequence' },
        {
          key: 'risk_owner',
          title: 'Risk Owner',
        },
        {
          key: 'expected_loss',
          title: 'Expected Loss',
        },
        {
          key: '_control_count',
          title: 'Linked Controls',
        },
        {
          key: 'updated_at',
          title: 'Last Updated',
        },
        {
          key: 'created_at',
          title: 'Created Date',
        },
      ]}
      defaultOptions={defaultColumns}
      value={selectedColumns}
      onChange={setSelectedColumns}
    />
  );

  return { columns, GTColumnSelectAnchorExported, checked, resetChecked: () => setChecked([]) };
};
