import React, { useState, createContext } from 'react';
import { FiltersType } from 'src/api/types/dashboard';

type ModalType = 'users' | 'events' | 'objects';

interface FiltersContextInterface {
  modalType: ModalType | null;
  setModalType: React.Dispatch<React.SetStateAction<ModalType | null>>;
  filters: FiltersType;
  setFilters: React.Dispatch<React.SetStateAction<FiltersType>>;
  isInit: boolean;
  setIsInit: React.Dispatch<React.SetStateAction<boolean>>;
}

const FiltersContext = createContext<Partial<FiltersContextInterface>>({});

const FiltersProvider = (props: any) => {
  const [isInit, setIsInit] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [filters, setFilters] = useState<FiltersType>({
    events: {
      Assessments: {},
      Scenarios: {},
      Controls: {},
      Incidents: {},
    },
    objects: {
      assessments: {},
      scenarios: {},
      controls: {},
      incidents: {},
    },
    owners: {},
  });

  return (
    <FiltersContext.Provider
      value={{
        modalType,
        setModalType,
        filters,
        setFilters,
        isInit,
        setIsInit,
      }}
      {...props}
    />
  );
};

const useFiltersState = () => {
  const context = React.useContext(FiltersContext);
  if (context === undefined) {
    throw new Error('useFiltersState must be used within a FiltersProvider');
  }
  return context as FiltersContextInterface;
};

export { FiltersProvider, useFiltersState };
