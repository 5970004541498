import { ActivityData, EventFilter, TimelineEvent } from 'src/api/types/dashboard';
import moment from 'moment';
import _ from 'lodash';

export function modEnabledRecursive<T>(obj: T, enabled: boolean): T {
  if (obj && typeof obj === 'object') {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        if (value && typeof value === 'object') {
          if ('enabled' in value) {
            value.enabled = enabled;
          }
          modEnabledRecursive(value, enabled);
        }
      }
    }
  }
  return obj;
}

export const splitEventsByDateRange = (
  data?: ActivityData[],
): { range1: TimelineEvent[]; range2: TimelineEvent[]; range3: TimelineEvent[] } => {
  const allEvents = _.flatMap(data, (item) => item.events.map((event) => ({ ...event, event_date: item.event_date })));

  const currentDate = moment();

  const range1: TimelineEvent[] = [];
  const range2: TimelineEvent[] = [];
  const range3: TimelineEvent[] = [];

  _.forEach(allEvents, (event) => {
    const eventDate = moment(event.event_date);
    const daysDiff = eventDate.diff(currentDate, 'days');

    if (daysDiff >= 0 && daysDiff < 7) {
      range1.push(event);
    } else if (daysDiff >= 7 && daysDiff < 30) {
      range2.push(event);
    } else if (daysDiff >= 30 && daysDiff < 90) {
      range3.push(event);
    }
  });

  return {
    range1: range1.filter((el) => el.type !== 'PAST_INCIDENT_CREATED'),
    range2: range2.filter((el) => el.type !== 'PAST_INCIDENT_CREATED'),
    range3: range3.filter((el) => el.type !== 'PAST_INCIDENT_CREATED'),
  };
};

export const mapToOptionList = (obj: { [key: string]: EventFilter }) => {
  if (!obj) return [];
  return Object.entries(obj).map(([label, { tag, enabled }]) => ({ tag: tag || label?.toUpperCase(), enabled, label }));
};
