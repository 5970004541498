import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useVersion from '../useVersion';
import { useAuth } from 'src/state/auth';
import { HeaderData } from 'src/redux/types';
import useActionsApp from 'src/redux/actionHooks/useActionsApp';
import _ from 'lodash';
import { useStateSelector } from 'src/redux';
import { mpEvent } from 'src/utils/mixpanel/useMixPanel';
import { MPEvents } from 'src/utils/mixpanel/types';

const useNavLocation = () => {
  const locationPaths = useStateSelector(({ app }) => app.location.paths);
  const { nav, appLoaded } = useAuth();
  const { pathname, search } = useLocation();
  const { version } = useVersion();
  const navigate = useNavigate();
  const { setLocationHeader } = useActionsApp();

  useEffect(() => {
    if (!appLoaded) {
      setLocationHeader([]);
      return;
    } else {
      const ndata: HeaderData[] = nav.orgPath?.map((el, idx) => {
        if (idx === 0) {
          const homePaths = ['/home', '/organisation', `/organisation/${el.id}`];
          const path = '/home';

          return {
            key: el.id,
            titleComponent: {
              title: el.name,
              iconHome: true,
              isActive: homePaths.includes(pathname),
            },
            isStart: true,
            onClick: () => mpEvent(MPEvents.NavClick, { value: 'Home', tags: ['NAVIGATION'] }),
            path,
          };
        } else {
          return {
            key: el.id,
            titleComponent: {
              title: el.name,
              isActive: false,
            },
            isNext: true,
            onClick: () => {
              if (el.type === 'O') {
                navigate(`/organisation/${el.id}`);
                mpEvent(MPEvents.NavClick, { value: 'Organisation', tags: ['NAVIGATION'] });
              }
              if (el.type === 'W') {
                navigate(`/risk-assessments`);
                mpEvent(MPEvents.NavClick, { value: 'Workspace', tags: ['NAVIGATION'] });
              }
            },
            path: el.type === 'O' ? `/organisation/${el.id}` : `/risk-assessments`,
          };
        }
      });

      // TIMELINE
      if (pathname.startsWith('/timeline')) {
        const path = '/timeline';
        const title = 'Timeline';

        ndata.push({
          key: path,
          titleComponent: {
            title,
            isActive: pathname === path,
          },
          isNext: true,
          onClick: () => mpEvent(MPEvents.NavClick, { value: title, tags: ['NAVIGATION'] }),
          path,
        });
      }

      // RISK ASSESSMENTS
      if (pathname.startsWith('/risk-assessments')) {
        const path = '/risk-assessments';

        ndata.push({
          key: path,
          titleComponent: {
            title: 'Risk Assessments',
            isActive: pathname === path,
          },
          isNext: true,
          onClick: () => mpEvent(MPEvents.NavClick, { value: 'Risk Assessments', tags: ['NAVIGATION', 'ASSESSMENT'] }),
          path,
        });

        if (locationPaths.assessment) {
          const path = `/risk-assessments/${locationPaths.assessment.id}${search}`;
          const title = locationPaths.assessment.name;

          ndata.push({
            key: path,
            titleComponent: {
              title,
              isActive: pathname + search === path,
            },
            isNext: true,
            onClick: () =>
              mpEvent(MPEvents.NavClick, { value: `Risk Assessments:Assessment`, tags: ['NAVIGATION', 'ASSESSMENT'] }),
            path,
          });
        }
        if (locationPaths.scenario && locationPaths.assessment) {
          const path = `/risk-assessments/${locationPaths.assessment.id}/scenario/${locationPaths.scenario.id}${search}`;
          const title = locationPaths.scenario.name;

          ndata.push({
            key: path,
            titleComponent: {
              title,
              isActive: pathname + search === path,
            },
            isNext: true,
            onClick: () =>
              mpEvent(MPEvents.NavClick, {
                value: `Risk Assessments:Assessment:Scenario`,
                tags: ['NAVIGATION', 'SCENARIO'],
              }),
            path,
          });
        }
      }

      // SCENARIO LIBRARY
      if (pathname.startsWith('/scenario-library')) {
        const path = '/scenario-library';
        const title = 'Scenario Library';

        ndata.push({
          key: path,
          titleComponent: {
            title,
            isActive: pathname === path,
          },
          isNext: true,
          onClick: () => mpEvent(MPEvents.NavClick, { value: title, tags: ['NAVIGATION'] }),
          path,
        });
      }

      // RMP
      if (pathname.startsWith('/risk-management')) {
        const path = '/risk-management';
        const title = 'Risk Management';

        ndata.push({
          key: path,
          titleComponent: {
            title,
            isActive: pathname === path,
          },
          isNext: true,
          onClick: () => mpEvent(MPEvents.NavClick, { value: title, tags: ['NAVIGATION'] }),
          path,
        });

        if (locationPaths.rmpControl) {
          const path = `/risk-management/control/${locationPaths.rmpControl.id}`;
          const title = locationPaths.rmpControl.name;

          ndata.push({
            key: path,
            titleComponent: {
              title: locationPaths.rmpControl.name,
              isActive: pathname === path,
            },
            isNext: true,
            onClick: () => mpEvent(MPEvents.NavClick, { value: `Risk Management:Control`, tags: ['NAVIGATION'] }),
            path,
          });
        }
      }

      // IMPROVEMENT PLANS
      // if (pathname.startsWith('/improvement-plans')) {
      //   const path = '/improvement-plans';
      //   const title = 'Improvement Plans';

      //   ndata.push({
      //     key: path,
      //     titleComponent: {
      //       title,
      //       isActive: pathname === path,
      //     },
      //     isNext: true,
      //     onClick: () => mpEvent(MPEvents.NavClick, { value: title, tags: ['NAVIGATION'] }),
      //     path,
      //   });
      // }

      // INCIDENTS
      if (pathname.startsWith('/incidents')) {
        const path = '/incidents';
        const title = 'Security Incidents';

        ndata.push({
          key: 'incidents',
          titleComponent: {
            title,
            isActive: pathname === path,
          },
          isNext: true,
          onClick: () => mpEvent(MPEvents.NavClick, { value: title, tags: ['NAVIGATION'] }),
          path,
        });

        if (locationPaths.incident) {
          const path = `/incidents/${locationPaths.incident.id}`;
          const title = locationPaths.incident.name;

          ndata.push({
            key: path,
            titleComponent: {
              title,
              isActive: pathname === path,
            },
            isNext: true,
            onClick: () => mpEvent(MPEvents.NavClick, { value: `Security Incidents:Incident`, tags: ['NAVIGATION'] }),
            path,
          });
        }
      }

      // CONTROL FRAMEWORKS
      if (pathname.startsWith('/control-frameworks')) {
        const path = '/control-frameworks';
        const title = 'Control Frameworks';

        ndata.push({
          key: path,
          titleComponent: {
            title,
            isActive: pathname === path,
          },
          isNext: true,
          onClick: () => mpEvent(MPEvents.NavClick, { value: title, tags: ['NAVIGATION'] }),
          path,
        });

        if (locationPaths.framework) {
          const id = locationPaths.framework.id;
          const path = `/control-frameworks/${id}`;
          const isActive = pathname === path;
          const title = locationPaths.framework.name;

          ndata.push({
            key: id,
            titleComponent: {
              title: locationPaths.framework.name,
              isActive,
            },
            isNext: true,
            onClick: () => mpEvent(MPEvents.NavClick, { value: `Control Frameworks:Framework`, tags: ['NAVIGATION'] }),
            path,
          });
        } else {
          if (pathname === '/control-frameworks/all-controls') {
            const path = '/control-frameworks/all-controls';
            const isActive = pathname === path;
            const title = 'All Controls';

            ndata.push({
              key: 'all-controls',
              titleComponent: {
                title,
                isActive,
              },
              isNext: true,
              onClick: () => mpEvent(MPEvents.NavClick, { value: title }),
              path,
            });
          }
        }

        if (locationPaths.frameworkControl) {
          const frameworkControlId = locationPaths.frameworkControl.id;
          const path = `/control-frameworks/all-controls/${frameworkControlId}`;
          const title = locationPaths.frameworkControl.name;

          ndata.push({
            key: frameworkControlId,
            titleComponent: {
              title,
            },
            isNext: true,
            onClick: () => mpEvent(MPEvents.NavClick, { value: `All Controls:Control`, tags: ['NAVIGATION'] }),
            path,
          });
        }
      }

      // AUDIT
      if (pathname.startsWith('/audit/workspace')) {
        const path = '/audit/workspace';
        const title = 'Audit Log';

        ndata.push({
          key: path,
          titleComponent: {
            title,
            isActive: pathname === path,
          },
          isNext: true,
          onClick: () => mpEvent(MPEvents.NavClick, { value: title, tags: ['NAVIGATION'] }),
          path,
        });
      }

      // WORKSPACE
      if (pathname.startsWith('/workspace')) {
        const path = '/workspace';
        const title = 'Workspace';

        ndata.push({
          key: path,
          titleComponent: {
            title,
            isActive: pathname === path,
          },
          isNext: true,
          onClick: () => mpEvent(MPEvents.NavClick, { value: title, tags: ['NAVIGATION'] }),
          path,
        });
      }

      // HELP
      if (pathname.startsWith('/get-started')) {
        const path = '/get-started';
        const title = 'Help';

        ndata.push({
          key: path,
          titleComponent: {
            title,
            isActive: pathname === path,
          },
          isNext: true,
          onClick: () => mpEvent(MPEvents.NavClick, { value: title, tags: ['NAVIGATION'] }),
          path,
        });
      }

      setLocationHeader(ndata);
    }
  }, [nav, pathname, version, locationPaths, appLoaded]);

  return null;
};

export default useNavLocation;
