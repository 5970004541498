import React from 'react';
import styled from 'styled-components';
import { CheckItem } from './comps';
import { Poppins } from 'src/common';

const Div = styled.div`
  display: grid;
  grid-gap: 10px;
  max-width: 100%;
  overflow: hidden;
`;

interface ListSelectorProps {
  data: { tag: string; label: string; enabled: boolean }[];
  selected: string[];
  onSelect: (tag: string) => void;
  onSelectAll?: (isAdd: boolean) => void;
  emptyText?: string;
  checkIsYou?: (option: { tag: string; label: string; enabled: boolean }) => boolean;
  allSelectorDisabled?: boolean;
}

export const ListSelector: React.FC<ListSelectorProps> = ({
  data,
  selected,
  onSelect,
  onSelectAll,
  emptyText,
  checkIsYou,
  allSelectorDisabled,
}) => {
  const isAllSelected = data.length === selected.length;

  return (
    <Div>
      {onSelectAll && !!data.length && !allSelectorDisabled && (
        <CheckItem title="All" isChecked={isAllSelected} onClick={() => onSelectAll(!isAllSelected)} />
      )}
      {data.map((el) => {
        const isYou = checkIsYou ? checkIsYou(el) : false;
        return (
          <CheckItem
            key={el.tag}
            title={el.label}
            isChecked={el.enabled}
            onClick={() => onSelect(el.tag)}
            isYou={isYou}
          />
        );
      })}
      {!data.length && emptyText && (
        <Poppins px={14} color="davysGrey">
          {emptyText}
        </Poppins>
      )}
    </Div>
  );
};
