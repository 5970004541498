import React from 'react';
import { Base } from './Base';
import { Text } from './Text';
import { MagCheckIcon } from './icons';
import { formatDate } from 'src/utils/misc';

interface EFComplianceAuditDueProps {
  showDate?: string;
}

export const EFComplianceAuditDue: React.FC<EFComplianceAuditDueProps> = ({ showDate }) => {
  return (
    <Base
      iconComponent={<MagCheckIcon />}
      constentComponent={
        <Text>
          {' '}
          {showDate && <Text className="sdate">{formatDate(showDate, { dateFormat: 'dd-MM-yyyy' })}</Text>}Compliance
          audit due.
        </Text>
      }
    />
  );
};
