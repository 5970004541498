import { riskConsequencesNames, riskEventsNames, riskSourcesNames } from 'src/api/types';
import { ScenarioCSV } from './types';
import { ScenarioCsvHeaders } from 'src/api/types/misc';

const _mapToNumberOrNullError = (
  list: { [key: string]: string },
  value: string | null,
  errorFieldName: string,
): number | string | null => {
  if (value === null || value === '') {
    return null;
  }

  const lowerCaseValue = value.toLowerCase();

  const entry = Object.entries(list).find(([, name]) => name.toLowerCase() === lowerCaseValue);
  if (entry) {
    return parseInt(entry[0], 10);
  }
  return `Invalid ${errorFieldName}`;
};

export const mapSourceToNumber = (sourceText: string | null): number | string | null => {
  return _mapToNumberOrNullError(riskSourcesNames, sourceText, 'Source');
};

export const mapEventToNumber = (eventText: string | null): number | string | null => {
  return _mapToNumberOrNullError(riskEventsNames, eventText, 'Event');
};

export const mapConsequenceToNumber = (consequenceText: string | null): number | string | null => {
  return _mapToNumberOrNullError(riskConsequencesNames, consequenceText, 'Consequence');
};

export const chunkArray = (array: ScenarioCSV[], size: number): ScenarioCSV[][] => {
  const chunkedArr = [];
  for (let i = 0; i < array.length; i += size) {
    chunkedArr.push(array.slice(i, i + size));
  }
  return chunkedArr;
};

export const generateCsvTemplateLink = () => {
  const csvString = Object.values(ScenarioCsvHeaders).join(',') + '\n';

  const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
  return URL.createObjectURL(blob);
};
