import React from 'react';
import colors from 'src/theme/colors';
import styled from 'styled-components';
import { Details } from './comps';
import { Control, ControllStatus } from 'src/api/types';
import { FractionBar, WCard } from '../comps';
import { FractionBarValue } from '../types';
import { genFractionBarsValues } from '../comps/FractionBar/util';
import { Poppins } from 'src/common';

const Div = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-gap: 9px;

  .fbars {
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-gap: 4px;

    &__item {
      padding: 0 25px 0 16px;
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;
      grid-gap: 20px;
    }
  }

  .c-details {
    min-width: 580px;
    flex: 1.3;
  }

  .c-bars {
    min-width: 300px;
    flex: 1;
  }

  ${({ theme }) => theme.breakpoints.down('lg')} {
    .c-details {
      min-width: 300px;
    }

    .c-bars {
      min-width: 300px;
    }
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    flex-direction: column;
  }
`;

interface WidgetRMPProps {
  controls: Control[];
}

export const WidgetRMP: React.FC<WidgetRMPProps> = ({ controls }) => {
  const notStartedControls = controls.filter((el) => el.status === ControllStatus.NotStarted);
  const notStartedCost = notStartedControls.reduce((acc, el) => acc + (el.annual_cost || 0), 0);

  const inProgressControls = controls.filter((el) => el.status === ControllStatus.InProgress);
  const inProgressCost = inProgressControls.reduce((acc, el) => acc + (el.annual_cost || 0), 0);

  const liveControls = controls.filter((el) => el.status === ControllStatus.Live);
  const liveCost = liveControls.reduce((acc, el) => acc + (el.annual_cost || 0), 0);

  const abandonedControls = controls.filter((el) => el.status === ControllStatus.Abandoned);
  const abandonedCost = abandonedControls.reduce((acc, el) => acc + (el.annual_cost || 0), 0);

  const otherControls = controls.filter((el) => !el.status);
  const otherCost = otherControls.reduce((acc, el) => acc + (el.annual_cost || 0), 0);

  const valuesTotal: FractionBarValue[] = [
    {
      color: colors.prussianBlue,
      label: 'Not Started',
      value: notStartedControls.length,
    },
    {
      color: colors.brightBlue,
      label: 'In Progress',
      value: inProgressControls.length,
    },
    {
      color: colors.red,
      label: 'Live',
      value: liveControls.length,
    },
    {
      color: colors.cflowerBlue,
      label: 'Other',
      value: abandonedControls.length + otherControls.length,
    },
  ];

  const valuesCost: FractionBarValue[] = [
    {
      color: colors.prussianBlue,
      label: 'Not Started',
      value: notStartedCost,
    },
    {
      color: colors.brightBlue,
      label: 'In Progress',
      value: inProgressCost,
    },
    {
      color: colors.red,
      label: 'Live',
      value: liveCost,
    },
    {
      color: colors.cflowerBlue,
      label: 'Other',
      value: abandonedCost + otherCost,
    },
  ];

  return (
    <Div>
      <WCard className="c-details">
        <Details controls={controls} />
      </WCard>
      <WCard className="c-bars">
        <div className="fbars">
          <div className="fbars__item">
            <Poppins px={14}>Total:</Poppins>
            <FractionBar values={genFractionBarsValues(valuesTotal)} disabled={!controls.length} />
          </div>
          <div className="fbars__item">
            <Poppins px={14}>Cost:</Poppins>
            <FractionBar values={genFractionBarsValues(valuesCost)} labelBottom disabled={!controls.length} />
          </div>
        </div>
      </WCard>
    </Div>
  );
};
