import { AxiosArgs, ThenArgs } from 'src/api/types';
import axios, { AxiosResponse } from 'axios';
import { encodeBody } from 'src/api/client';
import { ActivityData, FiltersType } from './types/dashboard';

type GetTimelineGeneric = (params?: { filters: FiltersType | null }) => Promise<
  AxiosArgs<
    ThenArgs<
      AxiosResponse<{
        total: number;
        today: number;
        data: ActivityData[];
        filters: FiltersType;
      }>
    >
  >
>;

export const getTimeline: GetTimelineGeneric = (data) => {
  const path = `/api/public/api/timeline`;
  return axios({
    method: 'POST',
    url: path,
    data: encodeBody(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data);
};
// ------------------------------------------------------------

export const getTimelineActions: GetTimelineGeneric = (data) => {
  const path = `/api/public/api/upcoming-actions`;
  return axios({
    method: 'POST',
    url: path,
    data: encodeBody(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data);
};
