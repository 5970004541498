import { ColumnDef, sortingFns } from '@tanstack/react-table';
import React, { useMemo, useState } from 'react';
import { Incident } from 'src/api/types';
import { GTCheckBox, GTColumnSelectAnchor, GTExpander, GTTdm } from 'src/components/GenericTable';
import { formatDate, formatUrl } from 'src/utils/misc';
import { filter } from 'lodash';
import { Link } from 'react-router-dom';
import { GTInternalIds } from 'src/components/GenericTable/types';
import _ from 'lodash';
import colors from 'src/theme/colors';
import { OpenInNew } from '@mui/icons-material';
import { gTCheckHandler } from 'src/components/GenericTable/util';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import useVersion from 'src/hooks/useVersion';
import { encodeQueryParams } from 'src/api/client';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { TableIds } from 'src/api/types/misc';
import { TLLink } from 'src/pages/DashboardPage/comps';

type UseColumns = (a: {
  data?: Incident[];
  isCheckerEnabled?: boolean;
  tdm?: {
    onEditClick?: boolean;
    onDeleteClick?: boolean;
    onUnlinkIncidentClick?: (id: string) => void;
  };
}) => {
  columns: ColumnDef<Incident>[];
  GTColumnSelectAnchorExported: JSX.Element;
  deleteModalId: string | null;
  setDeleteModalId: (id: string | null) => void;
  editData: Omit<Incident, 'scenarios'> | null;
  setEditData: (data: Omit<Incident, 'scenarios'> | null) => void;
  checked: string[];
  resetChecked: () => void;
};

const defaultColumns = ['refId', 'name', 'type', 'date', 'linkedScenarios'];

export const useColumnsIncidents: UseColumns = ({ data = [], isCheckerEnabled, tdm }) => {
  const [selectedColumns, setSelectedColumns] = useLocalStorage(
    `${TableIds.scenarioIncidents}-columns`,
    defaultColumns,
  );
  const [deleteModalId, setDeleteModalId] = useState<string | null>(null);
  const [editData, setEditData] = useState<Omit<Incident, 'scenarios'> | null>(null);
  const [checked, setChecked] = useState<string[]>([]);
  const { version, assessmentId } = useVersion();

  const columns = useMemo(() => {
    const internalIds = [GTInternalIds.expander, GTInternalIds.tdm];

    if (isCheckerEnabled) {
      internalIds.push(GTInternalIds.checker);
    } else {
      internalIds.filter((el) => el !== GTInternalIds.checker);
      if (checked.length > 0) setChecked([]);
    }

    const list: ColumnDef<Incident>[] = [
      {
        id: GTInternalIds.checker,
        header: ({ table }) => {
          const { isAllChecked, checkAllHandler } = gTCheckHandler(
            {
              checked,
              selector: 'id',
              table,
            },
            setChecked,
          );

          return <GTCheckBox isChecked={isAllChecked} onClick={checkAllHandler} />;
        },
        cell: ({ row }) => {
          const id = row.original.id;
          return (
            <GTCheckBox
              isChecked={checked.includes(id)}
              onClick={() => {
                if (checked.includes(id)) {
                  setChecked(checked.filter((el) => el !== id));
                } else {
                  setChecked([...checked, id]);
                }
              }}
            />
          );
        },
        size: 1,
        enableSorting: false,
      },
      {
        id: GTInternalIds.expander,
        header: () => null,
        cell: ({ row }) => {
          return row.getCanExpand() ? (
            <GTExpander onClick={row.getToggleExpandedHandler()} isExpanded={row.getIsExpanded()} />
          ) : null;
        },
        size: 1,
        enableSorting: false,
      },
      {
        id: 'refId',
        header: 'Reference',
        accessorFn: (row) => row.refId || null,
        cell: ({ row }) => (
          <>
            {formatUrl(row.original.source) ? (
              <a className="table-link" href={formatUrl(row.original.source)} target="_blank">
                {row.original.refId}{' '}
                <OpenInNew
                  css={`
                    font-size: 14px !important;
                    color: ${colors.prussianBlue};
                  `}
                />
              </a>
            ) : (
              row.original.refId
            )}
          </>
        ),
        sortingFn: sortingFns.text,
      },
      {
        id: 'name',
        header: 'Name',
        accessorFn: (row) => row.name,
        cell: (info) => (
          <Link
            className="table-link"
            to={`/incidents/${info.row.original.id}${version ? encodeQueryParams({ version, assessmentId }) : ''}`}
            onClick={() =>
              mpEvent(MPEvents.Link, {
                source: { value: ['Scenario'] },
                destination: { value: ['Incident'], params: { id: info.row.original.id } },
                tags: ['SCENARIO', 'INCIDENT'],
              })
            }
          >
            {info.getValue()}
          </Link>
        ),
        sortingFn: sortingFns.text,
        maxSize: 300,
      },
      {
        id: 'type',
        header: 'Type',
        accessorFn: (row) => row.type || null,
        cell: (info) => info.getValue(),
      },
      {
        id: 'date',
        header: 'Date',
        accessorFn: (row) => row.date || null,
        cell: ({ row }) => {
          const date = row.original.date;
          if (!date) return null;
          return <TLLink date={date}>{(formatDate(row.original.date), true)}</TLLink>;
        },
      },
      {
        id: 'linkedScenarios',
        header: 'Linked Scenarios',
        accessorFn: (row) => row.scenariosCount || null,
        cell: (info) => info.getValue(),
        sortingFn: sortingFns.alphanumeric,
      },

      {
        id: GTInternalIds.tdm,
        header: () => null,
        cell: ({ row }) => {
          const onEditClick =
            tdm?.onEditClick && !version ? () => setEditData(_.omit(row.original, 'scenarios')) : undefined;
          const onDeleteClick = tdm?.onDeleteClick && !version ? () => setDeleteModalId(row.original.id) : undefined;
          const onUnlinkIncidentClick =
            tdm?.onUnlinkIncidentClick && !version
              ? // @ts-ignore
                () => tdm.onUnlinkIncidentClick(row.original.id)
              : undefined;

          return (
            <div css="display: flex;grid-gap: 8px">
              <GTTdm
                onEditClick={onEditClick}
                onDeleteClick={onDeleteClick}
                onUnlinkIncidentClick={onUnlinkIncidentClick}
              />
            </div>
          );
        },
        size: 1,
      },
    ];

    return filter(list, (el) => [...internalIds, ...selectedColumns].includes(el.id as string));
  }, [data, selectedColumns, checked, isCheckerEnabled, tdm, version, assessmentId]);

  const GTColumnSelectAnchorExported = (
    <GTColumnSelectAnchor
      options={[
        { key: 'refId', title: 'Reference' },
        { key: 'name', title: 'Name' },
        { key: 'type', title: 'Type' },
        { key: 'date', title: 'Date' },
        { key: 'linkedScenarios', title: 'Linked Scenarios' },
      ]}
      defaultOptions={defaultColumns}
      value={selectedColumns}
      onChange={setSelectedColumns}
    />
  );

  return {
    columns,
    GTColumnSelectAnchorExported,
    deleteModalId,
    setDeleteModalId,
    editData,
    setEditData,
    checked,
    resetChecked: () => setChecked([]),
  };
};
