import React from 'react';
import styled from 'styled-components';

const Div = styled.div<{ $translate: number }>`
  position: absolute;
  background-color: white;
  border-radius: 6px;
  /* margin-top: 20px; */
  top: 20px;
  padding: 20px;
  box-shadow: 0px 0px 37px rgba(7, 46, 91, 0.23);
  min-width: 480px;
  max-height: 480px;
  overflow: auto;
  transform: translateX(${({ $translate }) => $translate}px);
`;

interface InfoPopupProps {
  contents: React.ReactNode;
  translate?: number;
}

export const InfoPopup: React.FC<InfoPopupProps> = ({ contents, translate = 0 }) => {
  return (
    <Div className="styled-scroll" $translate={translate ? translate - 480 : 0}>
      {contents}
    </Div>
  );
};
