import React from 'react';

export const ListIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
      <path
        opacity="0.2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 23C17.8513 23 23 17.8513 23 11.5C23 5.14873 17.8513 0 11.5 0C5.14873 0 0 5.14873 0 11.5C0 17.8513 5.14873 23 11.5 23Z"
        fill="url(#paint0_linear_4633_11140)"
      />
      <g opacity="0.5">
        <path
          d="M16.9841 8.57883C16.9841 8.10382 16.599 7.71875 16.124 7.71875H7.52317C7.04816 7.71875 6.66309 8.10382 6.66309 8.57883C6.66309 9.05385 7.04816 9.43892 7.52317 9.43892H16.124C16.599 9.43892 16.9841 9.05385 16.9841 8.57883Z"
          fill="url(#paint1_linear_4633_11140)"
        />
        <path
          d="M16.9841 12.0192C16.9841 11.5442 16.599 11.1591 16.124 11.1591H7.52317C7.04816 11.1591 6.66309 11.5442 6.66309 12.0192C6.66309 12.4942 7.04816 12.8793 7.52317 12.8793H16.124C16.599 12.8793 16.9841 12.4942 16.9841 12.0192Z"
          fill="url(#paint2_linear_4633_11140)"
        />
        <path
          d="M6.66309 15.4595C6.66309 14.9845 7.04816 14.5994 7.52317 14.5994H12.6837C13.1587 14.5994 13.5438 14.9845 13.5438 15.4595C13.5438 15.9345 13.1587 16.3196 12.6837 16.3196H7.52317C7.04816 16.3196 6.66309 15.9345 6.66309 15.4595Z"
          fill="url(#paint3_linear_4633_11140)"
        />
      </g>
      <defs>
        <linearGradient id="paint0_linear_4633_11140" x1="11.5" y1="0" x2="11.5" y2="23" gradientUnits="userSpaceOnUse">
          <stop stopColor="#E62E61" />
          <stop offset="1" stopColor="#FD5630" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4633_11140"
          x1="11.8236"
          y1="7.71875"
          x2="11.8236"
          y2="16.3196"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E62E61" />
          <stop offset="1" stopColor="#FD5630" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_4633_11140"
          x1="11.8236"
          y1="7.71875"
          x2="11.8236"
          y2="16.3196"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E62E61" />
          <stop offset="1" stopColor="#FD5630" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_4633_11140"
          x1="11.8236"
          y1="7.71875"
          x2="11.8236"
          y2="16.3196"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E62E61" />
          <stop offset="1" stopColor="#FD5630" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const ListIconGrey = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
      <path
        opacity="0.2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 23C17.8513 23 23 17.8513 23 11.5C23 5.14873 17.8513 0 11.5 0C5.14873 0 0 5.14873 0 11.5C0 17.8513 5.14873 23 11.5 23Z"
        fill="#6A7590"
      />
      <g opacity="0.5">
        <path
          d="M16.9841 8.57883C16.9841 8.10382 16.599 7.71875 16.124 7.71875H7.52317C7.04816 7.71875 6.66309 8.10382 6.66309 8.57883C6.66309 9.05385 7.04816 9.43892 7.52317 9.43892H16.124C16.599 9.43892 16.9841 9.05385 16.9841 8.57883Z"
          fill="#6A7590"
        />
        <path
          d="M16.9841 12.0192C16.9841 11.5442 16.599 11.1591 16.124 11.1591H7.52317C7.04816 11.1591 6.66309 11.5442 6.66309 12.0192C6.66309 12.4942 7.04816 12.8793 7.52317 12.8793H16.124C16.599 12.8793 16.9841 12.4942 16.9841 12.0192Z"
          fill="#6A7590"
        />
        <path
          d="M6.66309 15.4595C6.66309 14.9845 7.04816 14.5994 7.52317 14.5994H12.6837C13.1587 14.5994 13.5438 14.9845 13.5438 15.4595C13.5438 15.9345 13.1587 16.3196 12.6837 16.3196H7.52317C7.04816 16.3196 6.66309 15.9345 6.66309 15.4595Z"
          fill="#6A7590"
        />
      </g>
    </svg>
  );
};

export const CompileIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
      <path
        opacity="0.2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 23C17.8513 23 23 17.8513 23 11.5C23 5.14873 17.8513 0 11.5 0C5.14873 0 0 5.14873 0 11.5C0 17.8513 5.14873 23 11.5 23Z"
        fill="url(#paint0_linear_4633_11146)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.36914 9.62891L10.6418 12.6731C10.6986 12.7059 10.7581 12.7296 10.8187 12.7446V18.6486L5.74519 15.6461C5.5121 15.5082 5.36914 15.2574 5.36914 14.9866V9.62891ZM17.6312 9.54688V14.9855C17.6312 15.2563 17.4883 15.5071 17.2552 15.645L12.1816 18.6475V12.6918C12.194 12.6856 12.2063 12.679 12.2184 12.672L17.6312 9.54688Z"
        fill="url(#paint1_linear_4633_11146)"
      />
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.53516 8.14773C5.59956 8.0664 5.68085 7.99766 5.77562 7.94718L11.1401 5.08996C11.3653 4.97001 11.6354 4.97001 11.8606 5.08996L17.2251 7.94718C17.2981 7.9861 17.3632 8.03586 17.4186 8.09387L11.5371 11.4896C11.4984 11.5119 11.4627 11.5375 11.4303 11.5657C11.3978 11.5375 11.3621 11.5119 11.3234 11.4896L5.53516 8.14773Z"
        fill="url(#paint2_linear_4633_11146)"
      />
      <defs>
        <linearGradient id="paint0_linear_4633_11146" x1="11.5" y1="0" x2="11.5" y2="23" gradientUnits="userSpaceOnUse">
          <stop stopColor="#E62E61" />
          <stop offset="1" stopColor="#FD5630" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4633_11146"
          x1="11.5002"
          y1="9.54688"
          x2="11.5002"
          y2="18.6486"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E62E61" />
          <stop offset="1" stopColor="#FD5630" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_4633_11146"
          x1="11.4769"
          y1="5"
          x2="11.4769"
          y2="11.5657"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E62E61" />
          <stop offset="1" stopColor="#FD5630" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const CompileIconGray = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
      <path
        opacity="0.2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 23C17.8513 23 23 17.8513 23 11.5C23 5.14873 17.8513 0 11.5 0C5.14873 0 0 5.14873 0 11.5C0 17.8513 5.14873 23 11.5 23Z"
        fill="#6A7590"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.36914 9.22839L10.6418 12.2726C10.6986 12.3054 10.7581 12.329 10.8187 12.3441V18.2481L5.74519 15.2456C5.5121 15.1076 5.36914 14.8569 5.36914 14.5861V9.22839ZM17.631 9.14453V14.5831C17.631 14.854 17.488 15.1047 17.2549 15.2427L12.1814 18.2452V12.2894C12.1938 12.2832 12.206 12.2766 12.2181 12.2696L17.631 9.14453Z"
        fill="#6A7590"
      />
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.53516 7.74538C5.59956 7.66405 5.68085 7.59532 5.77562 7.54484L11.1401 4.68762C11.3653 4.56767 11.6354 4.56767 11.8606 4.68762L17.2251 7.54484C17.2981 7.58375 17.3632 7.63351 17.4186 7.69153L11.5371 11.0872C11.4984 11.1096 11.4627 11.1351 11.4303 11.1634C11.3978 11.1351 11.3621 11.1096 11.3234 11.0872L5.53516 7.74538Z"
        fill="#6A7590"
      />
    </svg>
  );
};

export const ChartIcon = () => {
  return (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 23C17.8513 23 23 17.8513 23 11.5C23 5.14873 17.8513 0 11.5 0C5.14873 0 0 5.14873 0 11.5C0 17.8513 5.14873 23 11.5 23Z"
        fill="url(#paint0_linear_185_55)"
      />
      <rect x="6.5" y="6.5" width="1.4286" height="10.0003" rx="0.714301" fill="url(#paint1_linear_185_55)" />
      <rect
        opacity="0.5"
        x="9.35742"
        y="9.22656"
        width="1.4286"
        height="7.27293"
        rx="0.714301"
        fill="url(#paint2_linear_185_55)"
      />
      <rect x="12.2148" y="13.3164" width="1.4286" height="3.18191" rx="0.7143" fill="url(#paint3_linear_185_55)" />
      <rect
        opacity="0.5"
        x="15.0713"
        y="7.86328"
        width="1.4286"
        height="8.63661"
        rx="0.714301"
        fill="url(#paint4_linear_185_55)"
      />
      <defs>
        <linearGradient id="paint0_linear_185_55" x1="11.5" y1="0" x2="11.5" y2="23" gradientUnits="userSpaceOnUse">
          <stop stopColor="#E62E61" />
          <stop offset="1" stopColor="#FD5630" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_185_55"
          x1="7.2143"
          y1="6.5"
          x2="7.2143"
          y2="16.5003"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E62E61" />
          <stop offset="1" stopColor="#FD5630" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_185_55"
          x1="10.0717"
          y1="9.22656"
          x2="10.0717"
          y2="16.4995"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E62E61" />
          <stop offset="1" stopColor="#FD5630" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_185_55"
          x1="12.9291"
          y1="13.3164"
          x2="12.9291"
          y2="16.4983"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E62E61" />
          <stop offset="1" stopColor="#FD5630" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_185_55"
          x1="15.7856"
          y1="7.86328"
          x2="15.7856"
          y2="16.4999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E62E61" />
          <stop offset="1" stopColor="#FD5630" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const ChartIconGrey = () => {
  return (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 23C17.8513 23 23 17.8513 23 11.5C23 5.14873 17.8513 0 11.5 0C5.14873 0 0 5.14873 0 11.5C0 17.8513 5.14873 23 11.5 23Z"
        fill="#6A7590"
      />
      <rect x="6.5" y="6.5" width="1.4286" height="10.0003" rx="0.714301" fill="#6A7590" />
      <rect opacity="0.5" x="9.35742" y="9.22656" width="1.4286" height="7.27293" rx="0.714301" fill="#6A7590" />
      <rect x="12.2148" y="13.3164" width="1.4286" height="3.18191" rx="0.7143" fill="#6A7590" />
      <rect opacity="0.5" x="15.0713" y="7.86328" width="1.4286" height="8.63661" rx="0.714301" fill="#6A7590" />
    </svg>
  );
};

export const WarnIcon = () => {
  return (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 23C17.8513 23 23 17.8513 23 11.5C23 5.14873 17.8513 0 11.5 0C5.14873 0 0 5.14873 0 11.5C0 17.8513 5.14873 23 11.5 23Z"
        fill="url(#paint0_linear_185_101)"
      />
      <path
        d="M17.1563 14.5992L12.2143 6.20148C12.0459 5.91839 11.753 5.75 11.4285 5.75C11.1063 5.75 10.811 5.91839 10.6451 6.20148L5.70311 14.5992C5.52983 14.892 5.52738 15.2434 5.69578 15.5387C5.86173 15.8267 6.15703 16 6.48649 16H16.3729C16.7023 16 16.9976 15.8267 17.1611 15.5387C17.332 15.2434 17.3295 14.892 17.1563 14.5992ZM10.9259 9.3754H11.751V10.1532L11.5741 12.3298H11.1027L10.9259 10.1532V9.3754ZM11.6508 13.608C11.5644 13.6964 11.4612 13.7407 11.3414 13.7407C11.2215 13.7407 11.1183 13.6964 11.032 13.608C10.9454 13.5196 10.9023 13.4142 10.9023 13.2915C10.9023 13.169 10.9454 13.0634 11.032 12.975C11.1183 12.8866 11.2215 12.8422 11.3414 12.8422C11.4612 12.8422 11.5644 12.8866 11.6508 12.975C11.7372 13.0634 11.7804 13.169 11.7804 13.2915C11.7804 13.4142 11.7372 13.5196 11.6508 13.608Z"
        fill="url(#paint1_linear_185_101)"
      />
      <defs>
        <linearGradient id="paint0_linear_185_101" x1="11.5" y1="0" x2="11.5" y2="23" gradientUnits="userSpaceOnUse">
          <stop stopColor="#E62E61" />
          <stop offset="1" stopColor="#FD5630" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_185_101"
          x1="11.4295"
          y1="5.75"
          x2="11.4295"
          y2="16"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E62E61" />
          <stop offset="1" stopColor="#FD5630" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export const WarnIconGrey = () => {
  return (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 23C17.8513 23 23 17.8513 23 11.5C23 5.14873 17.8513 0 11.5 0C5.14873 0 0 5.14873 0 11.5C0 17.8513 5.14873 23 11.5 23Z"
        fill="#6A7590"
      />
      <path
        d="M17.1563 14.5992L12.2143 6.20148C12.0459 5.91839 11.753 5.75 11.4285 5.75C11.1063 5.75 10.811 5.91839 10.6451 6.20148L5.70311 14.5992C5.52983 14.892 5.52738 15.2434 5.69578 15.5387C5.86173 15.8267 6.15703 16 6.48649 16H16.3729C16.7023 16 16.9976 15.8267 17.1611 15.5387C17.332 15.2434 17.3295 14.892 17.1563 14.5992ZM10.9259 9.3754H11.751V10.1532L11.5741 12.3298H11.1027L10.9259 10.1532V9.3754ZM11.6508 13.608C11.5644 13.6964 11.4612 13.7407 11.3414 13.7407C11.2215 13.7407 11.1183 13.6964 11.032 13.608C10.9454 13.5196 10.9023 13.4142 10.9023 13.2915C10.9023 13.169 10.9454 13.0634 11.032 12.975C11.1183 12.8866 11.2215 12.8422 11.3414 12.8422C11.4612 12.8422 11.5644 12.8866 11.6508 12.975C11.7372 13.0634 11.7804 13.169 11.7804 13.2915C11.7804 13.4142 11.7372 13.5196 11.6508 13.608Z"
        fill="#6A7590"
      />
    </svg>
  );
};

export const MagCheckIcon = () => {
  return (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 23C17.8513 23 23 17.8513 23 11.5C23 5.14873 17.8513 0 11.5 0C5.14873 0 0 5.14873 0 11.5C0 17.8513 5.14873 23 11.5 23Z"
        fill="url(#paint0_linear_185_66)"
      />
      <path
        d="M17 17L13.6667 13.6667M14.7778 10.8889C14.7778 11.3996 14.6772 11.9053 14.4818 12.3771C14.2863 12.8489 13.9999 13.2776 13.6387 13.6387C13.2776 13.9999 12.8489 14.2863 12.3771 14.4818C11.9053 14.6772 11.3996 14.7778 10.8889 14.7778C10.3782 14.7778 9.8725 14.6772 9.40068 14.4818C8.92885 14.2863 8.50015 13.9999 8.13903 13.6387C7.77791 13.2776 7.49146 12.8489 7.29602 12.3771C7.10059 11.9053 7 11.3996 7 10.8889C7 9.85749 7.40972 8.86834 8.13903 8.13903C8.86834 7.40972 9.85749 7 10.8889 7C11.9203 7 12.9094 7.40972 13.6387 8.13903C14.3681 8.86834 14.7778 9.85749 14.7778 10.8889Z"
        stroke="url(#paint1_linear_185_66)"
        strokeWidth="1.11111"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 9.78571C13 9.85882 12.9721 9.93196 12.9163 9.98768L10.6306 12.2734C10.575 12.3295 10.5018 12.3571 10.4286 12.3571C10.3554 12.3571 10.2823 12.3292 10.2266 12.2734L9.08375 11.1306C9.02795 11.075 9 11.0018 9 10.9286C9 10.7654 9.13348 10.6429 9.28571 10.6429C9.35882 10.6429 9.43196 10.6708 9.48768 10.7266L10.4286 11.6679L12.5125 9.58393C12.5679 9.52795 12.6411 9.5 12.7143 9.5C12.867 9.5 13 9.62232 13 9.78571Z"
        fill="url(#paint2_linear_185_66)"
      />
      <defs>
        <linearGradient id="paint0_linear_185_66" x1="11.5" y1="0" x2="11.5" y2="23" gradientUnits="userSpaceOnUse">
          <stop stopColor="#E62E61" />
          <stop offset="1" stopColor="#FD5630" />
        </linearGradient>
        <linearGradient id="paint1_linear_185_66" x1="12" y1="7" x2="12" y2="17" gradientUnits="userSpaceOnUse">
          <stop stopColor="#E62E61" />
          <stop offset="1" stopColor="#FD5630" />
        </linearGradient>
        <linearGradient id="paint2_linear_185_66" x1="11" y1="9.5" x2="11" y2="12.3571" gradientUnits="userSpaceOnUse">
          <stop stopColor="#E62E61" />
          <stop offset="1" stopColor="#FD5630" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const ShieldIcon = () => {
  return (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 23C17.8513 23 23 17.8513 23 11.5C23 5.14873 17.8513 0 11.5 0C5.14873 0 0 5.14873 0 11.5C0 17.8513 5.14873 23 11.5 23Z"
        fill="url(#paint0_linear_185_83)"
      />
      <path
        d="M16.6477 10.5651L16.2087 7.71179C13.0194 7.71179 11.4248 6.11719 11.4248 6.11719C11.4248 6.11719 9.83022 7.71179 6.64101 7.71179L6.20196 10.5651C6.01887 11.7536 6.20669 12.9696 6.73983 14.0475C7.27297 15.1254 8.12549 16.0127 9.18122 16.5884L11.4248 17.811L13.6684 16.5884C14.7242 16.0127 15.5767 15.1254 16.1098 14.0475C16.643 12.9696 16.8308 11.7536 16.6477 10.5651ZM13.9268 10.7453L10.7376 13.9345C10.6379 14.0341 10.5027 14.0901 10.3618 14.0901C10.2208 14.0901 10.0856 14.0341 9.98596 13.9345L8.92289 12.8714C8.87213 12.8224 8.83163 12.7637 8.80377 12.6989C8.77592 12.634 8.76125 12.5643 8.76064 12.4937C8.76003 12.4231 8.77348 12.3531 8.8002 12.2878C8.82693 12.2225 8.8664 12.1631 8.9163 12.1132C8.96621 12.0633 9.02556 12.0239 9.09088 11.9971C9.15621 11.9704 9.2262 11.957 9.29678 11.9576C9.36735 11.9582 9.4371 11.9728 9.50195 12.0007C9.5668 12.0286 9.62545 12.0691 9.67448 12.1198L10.3618 12.8071L13.1752 9.99368C13.2754 9.89685 13.4097 9.84328 13.5491 9.84449C13.6884 9.8457 13.8217 9.9016 13.9203 10.0002C14.0188 10.0987 14.0747 10.232 14.076 10.3714C14.0772 10.5108 14.0236 10.645 13.9268 10.7453Z"
        fill="url(#paint1_linear_185_83)"
      />
      <defs>
        <linearGradient id="paint0_linear_185_83" x1="11.5" y1="0" x2="11.5" y2="23" gradientUnits="userSpaceOnUse">
          <stop stopColor="#E62E61" />
          <stop offset="1" stopColor="#FD5630" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_185_83"
          x1="11.4248"
          y1="6.11719"
          x2="11.4248"
          y2="17.811"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E62E61" />
          <stop offset="1" stopColor="#FD5630" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const NoActionIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
      <path
        opacity="0.2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 23C17.8513 23 23 17.8513 23 11.5C23 5.14873 17.8513 0 11.5 0C5.14873 0 0 5.14873 0 11.5C0 17.8513 5.14873 23 11.5 23Z"
        fill="url(#paint0_linear_8548_23382)"
      />
      <path
        d="M15.3588 6.98787H14.6859C14.4313 6.98787 14.2249 6.78147 14.2249 6.52686C14.2249 6.23709 13.9876 6 13.6976 6C13.4075 6 13.1702 6.23709 13.1702 6.52686C13.1702 6.78147 12.9638 6.98787 12.7092 6.98787H10.7043C10.4497 6.98787 10.2433 6.78147 10.2433 6.52686C10.2433 6.23709 10.006 6 9.71594 6C9.42589 6 9.18857 6.23709 9.18857 6.52686C9.18857 6.78147 8.98217 6.98787 8.72757 6.98787H8.05473C7.47463 6.98787 7 7.46205 7 8.0416V15.3914C7 15.9709 7.47463 16.4451 8.05473 16.4451H15.3456C15.9257 16.4451 16.4003 15.9709 16.4003 15.3914V8.0416C16.4135 7.46205 15.9389 6.98787 15.3588 6.98787ZM15.3456 13.8114C15.3456 14.6767 14.6441 15.3782 13.7788 15.3782H9.6215C8.7562 15.3782 8.05473 14.6767 8.05473 13.8114V12.5193C8.05473 11.654 8.7562 10.9525 9.6215 10.9525H13.7788C14.6441 10.9525 15.3456 11.654 15.3456 12.5193V13.8114Z"
        fill="url(#paint1_linear_8548_23382)"
      />
      <defs>
        <linearGradient id="paint0_linear_8548_23382" x1="11.5" y1="0" x2="11.5" y2="23" gradientUnits="userSpaceOnUse">
          <stop stopColor="#E62E61" />
          <stop offset="1" stopColor="#FD5630" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8548_23382"
          x1="7"
          y1="6"
          x2="18.1825"
          y2="8.90709"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0001" stopColor="#E62E78" />
          <stop offset="1" stopColor="#FD5630" />
        </linearGradient>
      </defs>
    </svg>
  );
};
