import React from 'react';
import { Divider, Poppins } from 'src/common';
import { useFiltersState } from 'src/pages/DashboardPage/Filters/state';
import styled from 'styled-components';
import _ from 'lodash';

const Div = styled.div`
  position: relative;
  display: grid;
  flex-direction: column;
  grid-gap: 6px;
  .ip-title {
    margin: 4px 0;
  }
  .ip-divider {
    margin: 8px 0;
  }
`;

interface IPContentsObjectsProps {}

export const IPContentsObjects: React.FC<IPContentsObjectsProps> = () => {
  const { filters } = useFiltersState();

  const assessments = _.keys(_.pickBy(filters?.objects.assessments, (el) => el.enabled)).sort((a, b) =>
    a.localeCompare(b, undefined, { sensitivity: 'base' }),
  );
  const scenarios = _.keys(_.pickBy(filters?.objects.scenarios, (el) => el.enabled)).sort((a, b) =>
    a.localeCompare(b, undefined, { sensitivity: 'base' }),
  );
  const controls = _.keys(_.pickBy(filters?.objects.controls, (el) => el.enabled)).sort((a, b) =>
    a.localeCompare(b, undefined, { sensitivity: 'base' }),
  );
  const incidents = _.keys(_.pickBy(filters?.objects.incidents, (el) => el.enabled)).sort((a, b) =>
    a.localeCompare(b, undefined, { sensitivity: 'base' }),
  );

  return (
    <Div>
      {!!assessments.length && (
        <>
          <Poppins px={14} weight={500} color="cflowerBlue" className="ip-title">
            ASSESSMENTS
          </Poppins>
          {assessments.map((el) => (
            <Poppins key={el} px={14} weight={500} color="davysGrey" nowrap>
              {el}
            </Poppins>
          ))}
          {(!!scenarios.length || !!controls.length || !!incidents.length) && (
            <div className="ip-divider">
              <Divider />
            </div>
          )}
        </>
      )}
      {!!scenarios.length && (
        <>
          <Poppins px={14} weight={500} color="cflowerBlue" className="ip-title">
            SCENARIOS
          </Poppins>
          {scenarios.map((el) => (
            <Poppins key={el} px={14} weight={500} color="davysGrey" nowrap>
              {el}
            </Poppins>
          ))}
          {(!!controls.length || !!incidents.length) && (
            <div className="ip-divider">
              <Divider />
            </div>
          )}
        </>
      )}
      {!!controls.length && (
        <>
          <Poppins px={14} weight={500} color="cflowerBlue" className="ip-title">
            CONTROLS
          </Poppins>
          {controls.map((el) => (
            <Poppins key={el} px={14} weight={500} color="davysGrey" nowrap>
              {el}
            </Poppins>
          ))}
          {!!incidents.length && (
            <div className="ip-divider">
              <Divider />
            </div>
          )}
        </>
      )}
      {!!incidents.length && (
        <>
          <Poppins px={14} weight={500} color="cflowerBlue" className="ip-title">
            INCIDENTS
          </Poppins>
          {incidents.map((el) => (
            <Poppins key={el} px={14} weight={500} color="davysGrey" nowrap>
              {el}
            </Poppins>
          ))}
        </>
      )}
    </Div>
  );
};
