import React from 'react';
import { Poppins } from 'src/common';
import colors from 'src/theme/colors';
import styled from 'styled-components';

export const SDRDiv = styled.div`
  padding: 11px 6px;
  display: flex;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
  position: relative;
  grid-row-gap: 17px;

  &:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 10px;
    background: white;
    right: 0;
    bottom: 0;
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
  }
`;

const Div = styled.div`
  border-right: 1px solid ${colors.stroke};
  flex: 1;
  padding: 5px 15px 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2px;
  overflow: hidden;
  height: 70px;
  max-height: 70px;
  position: relative;

  ${({ theme }) => theme.breakpoints.down('md')} {
    height: 60px;
    max-height: 60px;
  }

  &:last-child {
    border-right: none;
  }

  .ditem__indicator {
    height: 6px;
    width: 16px;
    border-radius: 6px;
    margin-bottom: 9px;
    display: flex;
    justify-content: flex-end;
    position: relative;

    &.absolute {
      position: absolute;
    }

    &.black {
      background-color: ${colors.prussianBlue};
    }
    &.red {
      background-color: ${colors.red};
    }
    &.blue {
      background-color: ${colors.brightBlue};
    }
    &.gray {
      background-color: ${colors.cflowerBlue};
    }

    &.w6 {
      width: 6px;
    }
    &.w10 {
      width: 10px;
    }
    &.w14 {
      width: 14px;
    }
    &.w18 {
      width: 18px;
    }
  }

  .ditem__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${colors.prussianBlue};
    font-size: 14px;
    gap: 2px;
    overflow: hidden;
    &__title {
      white-space: nowrap;
    }
    &__value {
      font-weight: 600;
    }
  }
`;

export interface SDRRowData {
  title: string;
  value: string | number;
}

export type SDRIndicator = 'black' | 'red' | 'blue' | 'gray' | 'MIXED';

interface SDRItemProps {
  data: [SDRRowData, SDRRowData];
  indicator?: SDRIndicator;
}

export const SDRItem: React.FC<SDRItemProps> = ({ data, indicator }) => {
  return (
    <Div>
      {indicator !== 'MIXED' ? (
        <div className={`ditem__indicator ${indicator}`} />
      ) : (
        <div className="ditem__indicator black w18">
          <div className="ditem__indicator blue absolute w14" />
          <div className="ditem__indicator red absolute w10" />
          <div className="ditem__indicator gray absolute w6" />
        </div>
      )}
      {data.map((el, idx) => (
        <div className="ditem__row" key={idx}>
          <Poppins className="ditem__row__title" px={14}>
            {el.title}
          </Poppins>
          <Poppins className="ditem__row__value" px={14}>
            {el.value}
          </Poppins>
        </div>
      ))}
    </Div>
  );
};
