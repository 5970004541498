import React from 'react';
import styled from 'styled-components';
import { useFiltersState } from '../../Filters/state';
import { Chip } from './comps';
import _ from 'lodash';
import { modEnabledRecursive } from '../../util';
import { useAuth } from 'src/state/auth';
import { IPContentsEvents, IPContentsObjects, IPContentsUsers } from './comps/Chip/comps';
import { Spacer } from 'src/common';

const Div = styled.div`
  display: flex;
  grid-gap: 6px 12px;
  min-height: 27px;
  flex-wrap: wrap;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    display: none;
  }
`;

interface SelectionChipsProps {
  owners?: boolean;
}

export const SelectionChips: React.FC<SelectionChipsProps> = ({ owners }) => {
  const { userFeatures } = useAuth();
  const { filters, setFilters } = useFiltersState();

  const selectedEvents = _.chain(filters?.events)
    .values()
    .flatMap(_.values)
    .filter({ enabled: true })
    .size()
    .value();
  const totalEvents = _.chain(filters?.events)
    .values()
    .flatMap(_.values)
    .size()
    .value();

  const selectedObjects = _.chain(filters?.objects)
    .values()
    .flatMap(_.values)
    .filter({ enabled: true })
    .size()
    .value();
  const totalObjects = _.chain(filters?.objects)
    .values()
    .flatMap(_.values)
    .size()
    .value();

  const selectedOwners = _.chain(filters?.owners)
    .values()
    .filter({ enabled: true })
    .size()
    .value();
  const totalOwners = _.chain(filters?.owners)
    .values()
    .size()
    .value();

  const showOwners = !!selectedOwners && selectedOwners !== totalOwners;
  const showEvents = !!selectedEvents && selectedEvents !== totalEvents;
  const showObjects = !!selectedObjects && selectedObjects !== totalObjects;

  if (!userFeatures.app.timeline.actions || (!showOwners && !showEvents && !showObjects)) return null;

  return (
    <>
      <Spacer $px={20} />
      <Div>
        {showOwners && (
          <Chip
            title={owners ? 'Owners' : 'Users'}
            selected={selectedOwners}
            popupContents={<IPContentsUsers />}
            onClear={() =>
              setFilters((prev) => ({
                ...prev,
                owners: modEnabledRecursive(prev.owners, true),
              }))
            }
          />
        )}
        {showEvents && (
          <Chip
            title="Events"
            selected={selectedEvents}
            popupContents={<IPContentsEvents />}
            onClear={() =>
              setFilters((prev) => ({
                ...prev,
                events: modEnabledRecursive(prev.events, true),
              }))
            }
          />
        )}
        {showObjects && (
          <Chip
            title="Objects"
            selected={selectedObjects}
            popupContents={<IPContentsObjects />}
            onClear={() =>
              setFilters((prev) => ({
                ...prev,
                objects: modEnabledRecursive(prev.objects, true),
              }))
            }
            popupInverted={showOwners && showEvents}
          />
        )}
      </Div>
    </>
  );
};
