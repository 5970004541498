import React from 'react';
import { Poppins } from 'src/common';
import colors from 'src/theme/colors';
import styled, { css } from 'styled-components';

export const HeadTitle = styled(Poppins)<{ $highlightLinked?: boolean }>`
  color: ${colors.cflowerBlue};
  font-weight: 500;
  ${({ $highlightLinked }) =>
    $highlightLinked &&
    css`
      color: ${colors.gradientPart};
      font-weight: 600;
    `}
`;
